<template>
  <div :class="$style.contract">
    <slot>
      <h1>服务条款</h1>
      <p>若您申请三叠云并使用相应服务，您必须首先同意此协议。</p>
      <h3>一、接受</h3>
      <p>（1）当您使用服务时，您知晓并且同意此《服务条款》；</p>
      <p>
        （2）此协议在必要时将进行修改更新，网站发布后立即生效。属于政策性调整的，在30天内将通过电子邮件的方式通知帐户；
      </p>
      <p>
        （3）如果您拒绝接受新的协议，将被视为放弃使用三叠云网站提供的服务。若您继续使用三叠云提供的服务，则表明您接受新的协议；
      </p>
      <p>（4）除非特别声明，某些增强服务的新功能将适用此协议；</p>
      <p>（5）此协议只有三叠云书面授权人员才可以修改。</p>
      <h3>二、使用本协议</h3>
      <p>
        （1）此协议所述服务仅在三叠云网站内有效。三叠云网站是指
        https://www.sandieyun.com 及其所属网页；
      </p>
      <p>（2）三叠云免费版不会向客户收取任何费用，无相关附加服务；</p>
      <p>
        （3）三叠云有免费版，但不包括我们针对高级用户推出的付费版本和功能。如果您有意愿使用三叠云的某些付费服务，这将表示您同意支付其中的所有费用。在您提交使用付费产品的申请后，
        三叠云将提供经我们认可的第三方在线服务机构的支付方式，并要求您支付相关费用。成功支付后，表明您已经获得使用付费服务的权利并且已经达成此项交易，除非因三叠云的原因导致服务无法正常提供，否则我们将不退还您已经支付的服务；
      </p>
      <p>
        此外，由于您违反了《隐私声明》和《服务条款》的相关规定而导致帐户不可用，三叠云将不会退还付费产品在未使用期间的服务费。
      </p>
      <p>以下行为是我们坚决反对和禁止的：</p>
      <p>
        a.以恶意目的对本网站进行任何形式的反向工程、反向编译、反汇编，或在竞争产品抄袭模仿本网站的设计。
      </p>
      <p>b.违反《三叠云外链使用规范》的行为。</p>
      <p>
        c.对网站服务器进行恶意攻击，或者采取恶意手段使用三叠云，造成服务器异常。
      </p>
      <p>d.使用三叠云从事非法活动或者为非法活动提供帮助。</p>
      <p>e.法律法规规定的其他情形。</p>
      <p>
        如果您有上述行为，我们将视该行为引起的后果的严重性追究责任，并保留通过法律途径追偿合理损失的权利；
      </p>
      <p>（4）三叠云有权根据实际情况自主调整服务内容。</p>
      <h3>三、账户</h3>
      <p>（1）三叠云网站的帐户是能够承担相应法律责任的企业和个人；</p>
      <p>
        （2）三叠云网站要求帐户在使用服务时必须遵守相关法律法规。对帐户使用服务所产生的与其他公司或者个人的纠纷不负法律责任；
      </p>
      <p>（3）三叠云网站有权对恶意帐户中止服务，并无需特别通知；</p>
      <p>
        （4）三叠云网站的服务将不提供给那些被临时或永久取消会员资格的公司或个人；
      </p>
      <p>
        （5）对于免费账号，用户如注册后180天内未登录过，三叠云网站有权利清除帐号及其相关数据。您需自行承担数据被销毁后所引发的一切后果。
      </p>
      <h3>四、隐私保护</h3>
      <p>
        三叠云为用户提供了完备的隐私保护机制，具体请参阅《隐私声明》中的相关约定。
      </p>
      <h3>五、服务期限</h3>
      <p>
        三叠云网站有权判定三叠云帐户的行为是否符合本《服务协议》的要求，如果三叠云帐户违背了该《服务协议》的规定，三叠云网站有权决定停止该三叠云帐户的使用资格或者采取其他三叠云网站认为合适的措施。
      </p>
      <h3>六、服务终止</h3>
      <p>
        有下列情形之一的，三叠云网站有权随时暂停、终止、取消或拒绝帐户服务。
      </p>
      <p>（1）帐户违反了此协议或已在约定范围内的任一条款；</p>
      <p>（2）根据此协议相关说明而终止服务；</p>
      <p>（3）利用三叠云网站的发布功能滥发重复信息；</p>
      <p>（4）未经请求或授权向三叠云网站帐户发送大量与业务不相关的信息；</p>
      <p>（5）冒用其他企业的名义发布商业信息，进行商业活动；</p>
      <p>（6）攻击三叠云网站的数据、网络或服务；</p>
      <p>（7）盗用他人在三叠云网站上的帐户名或密码。</p>
      <p>以下信息是严格禁止并绝对终止帐户服务的：</p>
      <p>（1）有关宗教、种族或性别的贬损言辞；</p>
      <p>（2）骚扰、滥用或威胁其他帐户；</p>
      <p>
        （3）侵犯任何第三方著作权、专利、商标、商业秘密或其它专有权利、发表权或隐私权的信息；
      </p>
      <p>（4）严重违反《三叠云外链管理规范》的行为；</p>
      <p>（5）其它任何违反互联网相关法律法规的信息。</p>
      <h3>七、账号安全声明</h3>
      <p>
        在注册过程中，您可自主选择一个登录名和密码，并须对其保密性负责，同时对使用该登录名和密码的所有活动负责。您同意：
      </p>
      <p>
        ①对非授权使用您的登录名及密码以及其他破坏安全性的行为，帐户应立即向三叠云网站告知，三叠云网站将采取技术措施阻止恶意破坏；
      </p>
      <p>
        ②确保每次使用三叠云网站后正确地离开该站点。三叠云网站对您因没有遵守此协议而造成的损失不负任何法律责任；
      </p>
      <p>③因您保管不善可能导致遭受盗号或密码失窃，责任由您自行承担；</p>
      <p>
        ④对于使用第三方账户（如钉钉、明道等）登陆的用户，第三方账号安全与否与三叠云无关，对于因第三方账号出现的安全事故，三叠云不承担任何法律责任。
      </p>
      <h3>八、账户的权利和义务</h3>
      <p>（1）三叠云帐户服务生效后，帐户就可享受相应服务内容；</p>
      <p>
        （2）在使用三叠云网站提供的相应服务时必须保证遵守当地及中国有关法律法规的规定；不得利用该网站进行任何非法活动；遵守所有与使用该网站有关的协议、规定、程序和惯例；
      </p>
      <p>
        （3）如需修改自己的帐户信息资料，必须接受三叠云网站的审核与批准。如果使用虚假的帐户信息资料，三叠云有权终止其服务；
      </p>
      <p>（4）帐户对输入数据的准确性、可靠性、合法性、适用性等负责；</p>
      <p>
        （5）对由于帐户在使用三叠云网站服务的过程中，违反本协议或通过提及而纳入本协议的条款和规则或帐户违反任何法律或第三方的权利而产生或引起的每一
        种类似性质的任何索赔、要求、诉讼、损失和损害（实际、特别及后果性的）而言，无论是已知或未知的，包括合理的律师费，帐户同意就此对三叠云网站权利人、员工、所有者进行补偿并使其免受损害。
      </p>
      <h3>九、三叠云网站的权利和义务</h3>
      <p>（1）为三叠云账号提供三叠云网站承诺的服务；</p>
      <p>
        （2）三叠云网站服务的所有权和经营权未经书面许可仅属于三叠云网站权利人；
      </p>
      <p>
        （3）对于因不可抗力造成的服务中断、链接受阻或其他缺陷（包括但不限于自然灾害、社会事件以及因网站所具有的特殊性质而产生的包括黑客攻击、电信部门技术调整导致的影响、政府管制而造成的暂时性关闭在内的任何影响网络正常运营的因素），三叠云网站不承担任何责任，但将尽力减少因此而给会员造成的损失和影响；
      </p>
      <p>
        （4）三叠云网站要求使用者必须遵守国家相关的法律法规，三叠云有权决定删除帐户中关于任何违反中国法律法规的内容，或其他三叠云网站认为不可接受的内容。情节严重者，三叠云网站有权取消其帐户资格；
      </p>
      <p>
        （5）三叠云网站尽最大努力保证用户数据的安全与完整，并承诺提供短时间内的数据备份及恢复服务。
      </p>
      <h3>十、关于收费服务</h3>
      <p>
        1.
        您应知悉并认可，如您使用的本服务中的收费服务是由三叠云向您提供的，您应向三叠云支付对应的服务费用或产品费用。
      </p>
      <p>1.1 收费模式包含：</p>
      <p>
        1）单独向您提供某项具体的服务，并制定收费标准向您收取费用（例如“去除外链版权”、“空间扩容”）；
      </p>
      <p>
        2）未来可能添加的其他模式。您应知悉并认可，每类收费模式仅为三叠云针对本服务的部分内容所制定并采用，您按照某类收费模式所向三叠云支付对应费用后仅可获得三叠云所明示提供的对应部分的本服务，并非有权获得本服务的其他部分或全部。
      </p>
      <p>
        1.2
        三叠云可能根据实际运营情况对由其提供的服务的收费模式、收费标准、方式进行修改和变更，三叠云也可能会对由其提供的目前为免费使用的服务开始收费。如果您不同意上述修改、变更或付费内容，则应停止使用该部分服务。
      </p>
      <p>
        2.三叠云服务仅供您自行使用，您不得向任何第三方以有偿及无偿的形式转让使用权。您有责任保管自已的登陆密码，并定期更改更新。
      </p>
      <p>
        3.您应当基于合法目的使用三叠云服务，不得利用三叠云进行任何侵权、违法、违规行为，否则三叠云有权随时终止为您提供的部分或全部服务及产品，并不退还您已支付的费用。
      </p>
      <p>
        4.您在使用三叠云服务过程中与任何第三方所产生的纠纷与经济、法律责任需自担，均与三叠云无关。
      </p>
      <p>
        5.您所购买的三叠云服务版本到期如未续费将会降级为免费版或无法继续使用。
      </p>
      <p>
        6.您购买三叠云服务版本后，可以在版本期限内使用您购买版本内规定的所有功能，及在您购买之后三叠云为您所购买的版本新增的功能。
      </p>
      <p>
        7.您已知晓并认可，您所购买的三叠云服务版本，不包含在您购买之后三叠云新增的面向更高版本或单独出售的功能及服务。
      </p>
      <p>
        8.签署本协议后，除发生法律规定事项，协议双方无权单方面终止协议，如确因单方面原因经协商一致进行费用结算后，可终止协议，三叠云将退还服务费用。提出终止协议一方需向另一方支付服务费用总金额30%的违约金。
      </p>
      <h3>十一、最终解释权</h3>
      <p>三叠云网站权利人对三叠云网站保有任何活动、限制等的最终解释权</p>
      <h3>十二、版权声明</h3>
      <p>
        以任何方式使用三叠云提供的服务，包括但不限于基于三叠云搭建的数据管理应用等服务，所涉及的版权信息的解释权归三叠云所有。三叠云有权为推广您发布到模板中心的应用使用您的Logo，商标、字号、应用描述等信息。三叠云网站的所有内容版权属三叠云网站权利人所有，严禁未经三叠云网站权利人书面许可的任何形式的部分或全部拷贝使用。版权所有翻版必究。三叠云中的应用程序、源代码、LOGO、界面设计、应用程序编程接口（API）所关联的所有知识产权均归属帆软软件有限公司所有。
        除另有特别声明外，帆软提供本服务时所依托软件的著作权、专利权及其他知识产权均归帆软所有。上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经帆软同意、用户或相关权利人书面许可，
        任何人不得以任何形式进行使用或创造相关衍生作品。
      </p>
      <h3>十三、不可抗力及责任免除</h3>
      <p>
        您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。
        不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，
        包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，三叠云将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失，三叠云在法律允许的范围内免责。
      </p>
      <p>
        在法律允许的范围内，三叠云对以下情形导致的服务中断或受阻不承担责任：
      </p>
      <p>
        （1）用户须明白，本服务涉及到互联网及移动通讯等服务，鉴于计算机及互联网的特殊性，因黑客、病毒、Internet上通路的阻塞、电信部门技术或政策调整或三叠云网站为进行服务器配置、维护等造成的服务中断、不能满足用户要求等风险。开通服务的用户须承担以上风险，三叠云网站权利人对服务及其内容的有效性
        、正确性 、质量 、稳定性
        、可靠性、及时性、适用性、真实性、实用性、准确性或完整性等均不作任何陈述、承诺或保证；
      </p>
      <p>
        （2）如三叠云网站的系统发生故障影响到本服务的正常运行，三叠云网站承诺在第一时间内及时处理进行修复。但用户因此而产生的经济损失，三叠云网站不承担责任。此外，三叠云网站保留不经事先通知为维修保养、升级或其它目的暂停本服务的权利；
      </p>
      <p>
        （3）对于因甲方员工误操作导致的数据丢失、数据外泄等行为与三叠云无关；
      </p>
      <p>
        （4）三叠云网站对帐户使用三叠云网站上公布的信息而造成的损失或伤害以及帐户相信三叠云网站上公布的信息内容而做出的决定或采取的行动不负责任；
      </p>
      <p>
        （5）三叠云网站、员工、所有者对帐户使用或无法使用三叠云网站的服务而造成的直接的、间接的、偶尔的、特殊的或其他损害不负法律责任，即便事先被告知损害存在的可能性也是如此；
      </p>
      <p>（6）用户通过非三叠云授权的方式使用三叠云；</p>
      <p>（7）其他三叠云无法控制或合理预见的情形。</p>
      <p>
        您理解并同意，本服务并非为某些特定目的而设计，包括但不限于核设施、军事用途、
        医疗设施、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，
        三叠云不承担法律责任。
      </p>
      <h3>十四、法律适用</h3>
      <p>
        （1）三叠云网站与帐户中任何一方未履行协议所规定的责任均视为违约，按《合同法》及其相关司法解释规定处理；如双方在此协议范围内发生纠纷，应尽量友好协商解决。此协议适用中华人民共和国法律（不包括冲突法）；
      </p>
      <p>
        （2）如协商不成的，您同意将纠纷或争议提交乙方所在地有管辖权的人民法院管辖。如与此协议有关的某一特定事项缺乏明确法律规定，则应参照通用的国际商业惯例和行业惯例。本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
      </p>
      <h3>十五、联系我们</h3>
      <p>
        如果您对本协议或本服务有意见或建议，欢迎您与三叠云联系，联系方式为：at@sandieyun.com
      </p>
    </slot>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class ContractPage extends Vue {}
</script>
<style lang="less" module>
.contract {
  color: var(--font);
  width: 1200px;
  margin: 0 auto;
  h1 {
    font-size: 30px;
    font-weight: bold;
    margin: 55px 0 45px 0;
    text-align: center;
  }
  h3 {
    font-size: 22px;
    line-height: 55px;
    margin-bottom: 0;
    font-weight: bold;
  }
  p {
    margin-bottom: 0;
    line-height: 40px;
  }
  pre {
    line-height: 40px;
    white-space: pre-line;
    font-family: inherit;
    h3 {
      display: inline-block;
    }
  }
}
</style>
