import Vue from 'vue';
import VueRouter from 'vue-router';

import IndexPage from '@/views/index.vue';
import LoginPage from '@/views/login/index.vue';
import LoginDefaultPage from '@/views/login/default.vue';
import LoginPhonePage from '@/views/login/phone.vue';
import LoginEmailPage from '@/views/login/email.vue';
import LoginDingTalkPage from '@/views/login/dingtalk.vue';
import LoginWxworkPage from '@/views/login/wxwork.vue';
import RegisterPage from '@/views/register/phone.vue';
import RegisterByEmailPage from '@/views/register/email.vue';
import ResetPage from '@/views/reset/sms.vue';
import ResetByEmailPage from '@/views/reset/email.vue';
import SuccessPage from '@/views/success.vue';
import SelectPage from '@/views/select.vue';
import InvitePage from '@/views/invite.vue';
import ContractPage from '@/views/contract.vue';
import AuthBindPage from '@/views/auth-bind/index.vue';
import AuthBindAccountPage from '@/views/auth-bind/account.vue';
import AuthBindPhonePage from '@/views/auth-bind/phone.vue';
import AuthBindRegisterPage from '@/views/auth-bind/register.vue';
import NotFoundPage from '@/views/404.vue';
import PrivacyPage from '@/views/privacy.vue';
import SsoPage from '@/views/sso/index.vue';
import SsoOauthPage from '@/views/sso/oauth.vue';
import SsoCallbackPage from '@/views/sso/callback.vue';
import { createMobileUrl } from '@/utils';

Vue.use(VueRouter);

/** @type { import('vue-router').RouteConfig[] } */
const routes = [
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPage,
    meta: {
      title: '隐私协议',
    },
  },
  {
    path: '/contract',
    name: 'contract',
    component: ContractPage,
    meta: {
      title: '服务条款',
    },
  },
  {
    path: '/',
    component: IndexPage,
    meta: {
      title: '登录',
    },
    children: [
      {
        path: '/',
        component: LoginPage,
        children: [
          {
            path: '/',
            name: 'loginDefault',
            component: LoginDefaultPage,
          },
          {
            path: '/phone',
            name: 'loginPhone',
            component: LoginPhonePage,
          },
          {
            path: '/email',
            name: 'loginEmail',
            component: LoginEmailPage,
          },
          {
            path: '/dingtalk',
            name: 'loginDingTalk',
            component: LoginDingTalkPage,
          },
          {
            path: '/wxwork',
            name: 'loginWxwork',
            component: LoginWxworkPage,
          },
          {
            path: '/e/:customUrl',
            name: 'customLoginPage',
            component: LoginDefaultPage,
          },
        ],
      },
      {
        path: '/auth-bind',
        component: AuthBindPage,
        redirect: '/auth-bind/account',
        meta: {
          title: '第三方账号绑定',
        },
        children: [
          {
            path: '/auth-bind/account',
            component: AuthBindAccountPage,
          },
          {
            path: '/auth-bind/phone',
            component: AuthBindPhonePage,
          },
          {
            path: '/auth-bind/register',
            component: AuthBindRegisterPage,
          },
        ],
      },
      {
        path: '/register',
        component: RegisterPage,
        name: 'register',
        meta: {
          title: '注册',
        },
      },
      {
        path: '/register/email',
        component: RegisterByEmailPage,
        name: 'register-email',
        meta: {
          title: '注册',
        },
      },
      {
        path: '/reset',
        component: ResetPage,
        name: 'reset',
        meta: {
          title: '重置密码',
        },
      },
      {
        path: '/reset/email',
        component: ResetByEmailPage,
        name: 'reset-email',
        meta: {
          title: '重置密码',
        },
      },
      {
        path: '/success',
        component: SuccessPage,
        name: 'success',
        meta: {
          title: '注册成功',
        },
      },
      {
        path: '/select',
        component: SelectPage,
        name: 'select',
        meta: {
          title: '选择操作',
        },
      },
      {
        path: 'invite',
        component: InvitePage,
        meta: {
          mobile: route =>
            createMobileUrl('/invitation/inviteMember/index', {
              k: route.query.k,
            }),
        },
      },
    ],
  },
  {
    path: '/sso',
    component: SsoPage,
    meta: {
      title: '登录',
    },
    children: [
      {
        path: '/sso/cb',
        component: SsoCallbackPage,
        meta: {
          title: '授权登录',
        },
      },
      {
        path: '/sso/:type',
        component: SsoOauthPage,
        meta: {
          title: '授权登录',
        },
      },
    ],
  },
  /** @name 404页面 */
  {
    path: '*',
    component: NotFoundPage,
    name: '404',
    meta: {
      title: '404',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(to => {
  const titleRoute = [...to.matched].reverse().find(route => route.meta.title);
  const title = titleRoute && titleRoute.meta.title;
  if (!title) return;
  window.document.title = title;
});

export default router;
