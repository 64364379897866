import { ensureArray, stringify } from '@triascloud/utils';
import UAParser from 'ua-parser-js';
import { noop } from 'lodash';
import { createModuleUrl } from './createModuleUrl';

export function isLocalhost(origin) {
  return /^https?:\/\/localhost(:\d{1,5})?\/?$/.test(origin);
}

export function isSafari() {
  return navigator.vendor === 'Apple Computer, Inc.';
}

export { createModuleUrl } from './createModuleUrl';

/**
 * @name 异步验证指定表单字段
 * @param { import('ant-design-vue').FormModel } form
 * @param { string | string [] } fields
 * @return { Promise<string[]> }
 */
export function validateFieldAsync(form, fields) {
  return Promise.all(
    ensureArray(fields).map(
      field =>
        new Promise((resolve, reject) =>
          form.validateField(field, err =>
            err ? reject('form validate error') : resolve(field),
          ),
        ),
    ),
  );
}

export const originWhileList = process.env.VUE_APP_ORIGIN_WHITE_LIST
  ? process.env.VUE_APP_ORIGIN_WHITE_LIST.split(',')
  : [];

/**
 *
 * @param { string } path
 * @param { Record<string, string>} params
 */
export function createMobileUrl(path, params) {
  return `${createModuleUrl('m')}/#/pages${path}${
    params ? '?' + stringify(params) : ''
  }`;
}

/**
 * @name 检测路由是否存在移动端跳转
 * @param { import('vue-router').Route } route
 */
export function checkMobileRoute(route) {
  if (!route.meta?.mobile) return;
  const { type } = new UAParser().getDevice();
  if (['mobile', 'tablet'].includes(type || '')) {
    location.href = route.meta.mobile(route);
    return new Promise(noop);
  }
}
