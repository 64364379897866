<template>
  <div :class="[pageStyle.page, $style.select]">
    <div :class="pageStyle.pageHeader">{{ $t('select.operation') }}</div>
    <div :class="pageStyle.pageBody">
      <div :class="$style.tips">{{ $t('select.noJoin') }}：</div>
      <div :class="$style.list">
        <div
          v-for="item in selectData"
          :key="item.value"
          :class="[
            $style.item,
            { [$style.selectedItem]: currentSelect === item },
          ]"
          @click="handleSelectItem(item)"
        >
          <div :class="$style.icon">
            <x-icon :type="item.icon" />
            <x-icon
              v-if="currentSelect === item"
              :class="$style.check"
              type="tc-icon-check-bold"
            />
          </div>
          <div :class="$style.itemText">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div :class="[pageStyle.pageFooter, $style.footer]">
      <async-button type="primary" :click="handleNext">{{
        $t('select.next')
      }}</async-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Inject } from 'vue-property-decorator';
import { ssoClient } from '@triascloud/sso-client';
import { CreateCompany, JoinCompany } from '@triascloud/x-blocks';
import AsyncButton from '@/components/async-button';
import { SSOContext } from './context';
import { getCurrentUserAndTenant } from '@/services/tenant';
import { crossStorage } from '@triascloud/cross-storage';

@Component({
  components: { AsyncButton },
})
export default class SelectPage extends Vue {
  @Inject(SSOContext.PageStyle) pageStyle;
  @Inject(SSOContext.Root) root;
  value = 'create';
  get selectData() {
    return [
      {
        text: this.$t('select.create'),
        value: 'create',
        icon: 'tc-icon-company',
      },
      {
        text: this.$t('select.join'),
        value: 'join',
        icon: 'tc-icon-usergroup-add',
      },
      {
        text: this.$t('select.personal'),
        value: 'personal',
        icon: 'tc-icon-user',
      },
    ];
  }
  get currentSelect() {
    return this.selectData.find(item => item.value === this.value);
  }
  created() {
    const token = ssoClient.getTokenSync();
    if (!token) {
      this.$router.replace('/');
    }
  }
  handleSelectItem(item) {
    this.value = item.value;
  }
  async handleNext() {
    await this.getSelectValue();
    const { tenant } = await getCurrentUserAndTenant();
    crossStorage.setItem('tenant', JSON.stringify(tenant));
    this.root.redirect(true);
  }
  getSelectValue() {
    switch (this.value) {
      case 'create':
        return CreateCompany.createModal();
      case 'join':
        return JoinCompany.createModal();
      case 'personal':
        return;
    }
  }
}
</script>
<style lang="less" module>
div.select {
  width: 800px;
  font-size: 16px;
}
.tips {
  padding: 10px 36px;
  margin-bottom: 30px;
}
.list {
  display: flex;
  justify-content: space-around;
}
.item {
  cursor: pointer;
}
.icon {
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font-info);
  border: 1px dashed var(--border);
  position: relative;
  font-size: 80px;
}
.check {
  position: absolute;
  right: 6px;
  bottom: 6px;
  font-size: 36px;
}
.itemText {
  text-align: center;
  padding: 10px 0;
}
.item.selectedItem .icon {
  color: var(--primary);
  border: 1px solid var(--primary);
}
.footer {
  flex-direction: row-reverse;
}
</style>
