const r1 = 3;
const r2 = r1 + 1;
const r3 = r2 + 1;
const r4 = r3 + 1;
const r5 = r4 + 1;
const r6 = r5 + 1;
const r7 = r6 + 1;
const r8 = r7 + 1;

const logo = [
  ['', '', '', '', '', '', '', '', r2, '', '', '', '', '', '', '', ''],
  [r1, r1, '', '', '', '', '', '', r4, '', '', '', '', '', '', r1, r1],
  ['', r1, r2, r1, r2, r3, '', '', r6, '', '', r3, r2, r1, r2, r1, ''],
  ['', '', r1, r2, r3, r4, r5, r6, r7, r6, r5, r4, r3, r2, r1, '', ''],
  ['', '', r2, r3, r4, r5, r6, r7, r8, r7, r6, r5, r4, r3, r2, '', ''],
  ['', '', '', '', r3, r4, r5, r6, r7, r6, r5, r4, r3, '', '', '', ''],
  ['', '', '', '', '', '', '', r5, r6, r5, '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', r5, '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', r4, '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', r3, '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', r2, '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', r1, '', '', '', '', '', '', '', ''],
];

function generateBitmap(data, size = 24) {
  return data.flatMap((arr, row) =>
    arr.flatMap((radius, column) =>
      radius
        ? [
            {
              x: column * size + (size / 2 - radius),
              y: row * size + (size / 2 - radius),
              r: radius * 2,
            },
          ]
        : [],
    ),
  );
}

export default generateBitmap(logo);
