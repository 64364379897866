<template>
  <div :class="$style.wrap">
    <div ref="wxLogin"></div>
    <!-- <a-checkbox v-model="rememberMe">{{ $t('login.rememberMe') }}</a-checkbox> -->
  </div>
</template>
<script>
import { Component, Vue, Inject, Watch } from 'vue-property-decorator';
import { SSOContext } from '../context';
import { authLogin } from '@/services/oauth';
import * as wx from '@wecom/jssdk';
import { namespace } from 'vuex-class';

let instance = null;
@Component
export default class LoginWxworkPage extends Vue {
  @Inject(SSOContext.Root) root;
  @namespace('crossStorage').State locale;
  get app() {
    return this.root.wxWorkApp;
  }
  rememberMe = true;
  async handleLogin({ code }) {
    const token = await authLogin('qy_wx_other_account', {
      authCode: code,
      bindId: this.app.bindId,
    });
    this.$message.success(this.$t('login.loginSuccess'));
    await this.root.setStorage(token, this.rememberMe);
    this.root.redirect();
  }
  @Watch('locale')
  initCom() {
    if (!this.app) return;
    instance?.unmount();
    instance = wx.createWWLoginPanel({
      el: this.$refs.wxLogin,
      params: {
        login_type: 'CorpApp',
        appid: this.app.corpId,
        agentid: this.app.agentId,
        redirect_uri: `${location.origin}/third.html?to=${encodeURIComponent(this.root.redirectUrl)}&corpId=${this.app.corpId}&bindId=${this.app.bindId}`,
        state: 'STATE',
        redirect_type: 'callback',
        panel_size: 'small',
        lang: this.locale === 'en_US' ? 'en' : 'zh',
      },
      onLoginSuccess: this.handleLogin,
    });
  }
  mounted() {
    this.initCom();
  }
}
</script>
<style module type="less">
.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
