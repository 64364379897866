import request from './request';

const serviceName = '/third-party';

/** @name 获取企业微信绑定信息 */
export function getWxWorkApp(tenantId) {
  return request(`${serviceName}/qy-wechat/binding-data/${tenantId}`, {
    auth: false,
    showError: false,
  });
}
