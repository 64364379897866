<template>
  <div :class="$style.logo" @click="handleClick">
    <div :class="$style.wrapper">
      <animation-point
        v-for="(point, index) in renderData"
        :point="point"
        :floating="animating"
        :key="index"
      />
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { delay } from '@triascloud/utils';

import AnimationPoint from './animation-point.vue';
import logoData from './logo-data';

@Component({
  components: { AnimationPoint },
})
export default class AnimationLogo extends Vue {
  get originData() {
    return logoData;
  }

  animationData = [];

  animating = false;

  get renderData() {
    return this.animating ? this.animationData : this.originData;
  }

  async mounted() {
    await delay(2000);
    this.generateAnimationData();
    this.animating = true;
  }
  generateAnimationData() {
    const documentRect = document.documentElement.getBoundingClientRect();
    const logoRect = this.$el.getBoundingClientRect();
    const logoTop = logoRect.top - documentRect.top;
    const logoLeft = logoRect.left - documentRect.left;
    this.animationData = this.originData.map(point => ({
      ...point,
      x: Math.random() * documentRect.width - logoLeft,
      y: Math.random() * documentRect.height - logoTop,
      r: (Math.random() * 5.4 + 0.1) * point.r,
      opacity: Math.random() * 0.4 + 0.1,
    }));
  }

  async handleClick() {
    if (!this.animating) return;
    this.animating = false;
    await delay(5000);
    this.animating = true;
  }
}
</script>
<style lang="less" module>
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.logo {
  width: calc(24px * 17);
  height: calc(24px * 12);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
