<template>
  <div :class="pageStyle.page" v-if="!loading">
    <div :class="pageStyle.pageHeader">{{ $t('invite.join') }}</div>
    <div :class="[pageStyle.pageBody, $style.content]">
      <p v-if="invalid">{{ $t('invite.failure') }}</p>
      <template v-if="info.type === 'ADDED'">
        <p>{{ addedInviteText }}</p>
        <p>{{ $t('invite.improveInformation') }}</p>
      </template>
      <p v-else-if="info.type === 'URL'">
        {{ urlInviteText }}
      </p>
    </div>
    <div :class="[pageStyle.pageFooter, $style.footer]">
      <async-button
        v-if="root.alreadyLogin"
        type="primary"
        ghost
        :click="handleInviteJoin"
      >
        {{ buttonText }}
      </async-button>
      <router-button v-else type="primary" ghost to="/phone">
        {{ buttonText }}
      </router-button>
    </div>
  </div>
  <a-spin v-else />
</template>
<script>
import { Component, Inject, Vue } from 'vue-property-decorator';
import AsyncButton from '@/components/async-button';
import RouterButton from '@/components/router-button';
import { getInviteInfo, inviteJoin, switchEnterprise } from '@/services/tenant';
import { setUserInfo } from '@/services/storage';
import { SSOContext } from './context';
import { delay } from '@triascloud/utils';
import { ResponseError } from '@/enum';

@Component({
  components: { AsyncButton, RouterButton },
})
export default class InvitePage extends Vue {
  @Inject(SSOContext.PageStyle) pageStyle;
  @Inject(SSOContext.Root) root;
  /** @name 失效的邀请链接 */
  invalid = false;
  loading = true;
  info = {
    tenantInfo: {},
    memberInfo: {},
    type: '',
  };
  get addedInviteText() {
    return `${this.info.memberInfo.memberName} ${this.$t(
      'invite.addEnterprise',
    )}[${this.info.tenantInfo.enterpriseName}]`;
  }
  get urlInviteText() {
    return `[${this.info.tenantInfo.enterpriseName}]${this.$t(
      'invite.joinRequest',
    )}`;
  }
  get buttonText() {
    return this.invalid || this.info.type === 'ADDED'
      ? this.$t('invite.login')
      : this.$t('invite.joinTeam');
  }
  async created() {
    const { inviteKey } = this.root;
    if (!inviteKey) {
      this.$router.push('/');
      return;
    }
    try {
      await delay(1000);
      this.info = await getInviteInfo(inviteKey);
    } catch (e) {
      this.invalid = true;
    } finally {
      this.loading = false;
    }
  }
  async handleInviteJoin() {
    await inviteJoin(this.root.inviteKey).catch(e => {
      if (e.code === ResponseError.NOT_LOGIN) {
        this.root.alreadyLogin = false;
        this.$router.push('/phone');
      }
      return Promise.reject(e);
    });
    if (this.info.tenantInfo.pkId) {
      await switchEnterprise(this.info.tenantInfo.pkId);
      await setUserInfo();
      this.$message.success(
        this.$t('invite.joinSuccess', {
          name: this.info.tenantInfo.enterpriseName,
        }),
      );
      await delay(2000);
    }
    this.root.redirect(true);
  }
}
</script>
<style lang="less" module>
.content {
  text-align: center;
  font-size: 18px;
  padding: 45px 20px 25px;
}
.countDown {
  color: var(--primary);
}
.footer {
  flex-direction: row-reverse;
}
</style>
