<template>
  <div class="qrc qrc--dingtalk" v-if="!refreshFlag">
    <iframe class="qrc--iframe" :src="iframePath" allowTransparency />
    <iframe v-if="loginTmpCode" class="qrc--hidden" :src="callbackPath" />
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { parse, stringify } from '@triascloud/utils';
import { createModuleUrl } from '@/utils';

@Component()
export default class QrConnectDingtalk extends Vue {
  created() {
    window.addEventListener('message', this.handleMessage);
  }
  beforeDestroyed() {
    window.removeEventListener('message', this.handleMessage);
  }

  loginTmpCode = null;

  get callbackOrigin() {
    return createModuleUrl('account');
  }

  get authPath() {
    const params = stringify({
      appid: process.env.VUE_APP_DINGTALK_APP_ID,
      response_type: 'code',
      scope: 'snsapi_login',
      state: 'STATE',
      redirect_uri: `${this.callbackOrigin}/callback.html`,
    });
    return `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?${params}`;
  }

  get callbackPath() {
    return `${this.authPath}&loginTmpCode=${this.loginTmpCode}`;
  }

  get iframePath() {
    return `https://login.dingtalk.com/login/qrcode.htm?${stringify({
      goto: this.authPath,
      style: 'border: none;background-color: transparent;',
    })}`;
  }

  handleMessage(ev) {
    if (ev.origin === 'https://login.dingtalk.com') {
      this.loginTmpCode = ev.data;
    } else if (
      ev.origin === this.callbackOrigin &&
      ev.data &&
      ev.data.key === 'sso-login-callback'
    ) {
      this.$emit('connect', parse((ev.data.value || '').replace(/^\?/, '')));
    }
  }

  refreshFlag = false;
  async refresh() {
    this.refreshFlag = true;
    this.loginTmpCode = null;
    await this.$nextTick();
    this.refreshFlag = false;
  }
}
</script>
