<template>
  <router-view />
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ThemeSelect } from '@triascloud/x-blocks';

@Component()
export default class App extends Vue {
  @namespace('crossStorage').State skin
  created() {
    ThemeSelect.setTheme(document.documentElement, this.skin);
  }
}
</script>
