import { stringify } from '@triascloud/utils';
import { createModuleUrl } from '@/utils';
import { getOauth } from '@/services/oauth';

export default class Oauth {
  callbackOrigin = createModuleUrl('account');
  /**
   * @name appid传参区分
   * @description 1.qq:client_id 2.支付宝:app_id 3.微信:appid
   */
  appid(type) {
    switch (type) {
      case 'qq':
        return 'client_id';
      case 'alipay':
        return 'app_id';
      case 'wechatopen':
        return 'appid';
      default:
        break;
    }
  }

  /** @name 授权跳转URL */
  getAuthUrl(type) {
    switch (type) {
      case 'wechatopen':
        return 'https://open.weixin.qq.com/connect/qrconnect';
      case 'alipay':
        return 'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm';
      case 'qq':
        return 'https://graph.qq.com/oauth2.0/authorize';
      default:
        break;
    }
  }

  /** @name 跳转授权 */
  async toAuthLogin(type) {
    const data = await getOauth(type);
    const params = stringify({
      scope: data.scope,
      state: data.state,
      [this.appid(type)]: data.appid,
      response_type: 'code',
      redirect_uri: `${this.callbackOrigin}/callback.html?type=${type}`,
    });
    const url = `${this.getAuthUrl(type)}?${params}`;
    window.open(
      type === 'alipay'
        ? `https://auth.alipay.com/login/index.htm?${stringify({ goto: url })}`
        : url,
      '_blank',
    );
  }
}
