<template>
  <a-button v-bind="$attrs">
    <router-link v-bind="$props">
      <slot />
    </router-link>
  </a-button>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Button as AButton } from 'ant-design-vue';

@Component({
  components: { AButton },
})
export default class RouterButton extends Vue {
  @Prop([String, Object]) to;
  @Prop(Boolean) exact;
  @Prop(Boolean) append;
  @Prop(Boolean) replace;
  @Prop(String) activeClass;
  @Prop(String) exactActiveClass;
}
</script>
