export const ResponseError = {
  /** @name 未登录或登录状态失效 */
  NOT_LOGIN: 401,
  LOGIN_USER_NOT_FOUND: 10000,
  LOGIN_PASSWORD_ERROR: 10001,
  LOGIN_NEED_CAPTCHA: 10011,
  LOGIN_CAPTCHA_ERROR: 10012,
  LOGIN_BIND_ERROR: 10168,
  LOGIN_NEED_VERIFY_CODE: 10054,
  LOGIN_VERIFY_CODE_ERROR: 100130,
};
