import cookie from 'js-cookie';
import { crossStorage } from '@triascloud/cross-storage';
import { ThemeSelect } from '@triascloud/x-blocks';
import { SSOClient } from '@triascloud/sso-client';
import { ensureLocale } from '@triascloud/i18n';
import { isSafari } from '@/utils';
import { getCurrentUserAndTenant } from './tenant';
import { getHabit } from './habit';
import { delay } from '@triascloud/utils';

export async function setStorage(token, rememberMe) {
  const expiredAt = Date.now() + 7 * 24 * 3600 * 1000; // 七天免登录
  crossStorage.setItem(
    'storageType',
    rememberMe ? 'localStorage' : 'sessionStorage',
  );
  // 通过设置一个有效期为session的cookie，在下次打开时检测此cookie有效性，来实现关闭浏览器后退出登录
  if (!rememberMe) {
    cookie.set('status', 'ok');
  }
  crossStorage.setItem(SSOClient.TOKEN_KEY, token);
  if (isSafari()) {
    cookie.set(SSOClient.TOKEN_KEY, token, {
      expires: Math.max(expiredAt / (24 * 3600 * 1000), 1),
    });
  }
  crossStorage.setItem(SSOClient.EXPIRED_KEY, expiredAt);
  await setUserInfo();
  crossStorage.setItem('storageLoaded', Date.now());
  return delay(500);
}

export async function setUserInfo() {
  const [{ user, tenant }, skin, locale] = await Promise.all([
    getCurrentUserAndTenant(),
    getHabit('skin'),
    getHabit('locale'),
  ]);
  crossStorage.setItem('user', JSON.stringify(user));
  crossStorage.setItem('tenant', JSON.stringify(tenant));
  crossStorage.setItem('skin', JSON.stringify(ThemeSelect.ensureTheme(skin)));
  crossStorage.setItem('locale', ensureLocale(locale));
}

export function clearStorage() {
  crossStorage.removeItem(SSOClient.TOKEN_KEY);
  if (isSafari()) {
    cookie.remove(SSOClient.TOKEN_KEY);
  }
  crossStorage.removeItem(SSOClient.EXPIRED_KEY);
  crossStorage.removeItem('user');
  crossStorage.removeItem('tenant');
}
