<template>
  <div :class="pageStyle.page">
    <div :class="pageStyle.pageHeader">{{ $t('register.success') }}</div>
    <div :class="[pageStyle.pageBody, $style.content]">
      <span>{{ $t('register.congratulation') }}。</span>
      <span :class="$style.countDown">{{ `${countDownSecond}s` }}</span>
      <span>{{ $t('register.enterSystem') }}...</span>
    </div>
    <div :class="[pageStyle.pageFooter, $style.footer]">
      <a-button @click="redirect" ghost type="primary">{{
        $t('register.into')
      }}</a-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Inject } from 'vue-property-decorator';
import { delay } from '@triascloud/utils';
import { SSOContext } from './context';

@Component
export default class SuccessPage extends Vue {
  @Inject(SSOContext.PageStyle) pageStyle;
  @Inject(SSOContext.Root) root;

  countDownSecond = 3;

  async countDown(second) {
    this.countDownSecond = second;
    if (second <= 0) return;
    await delay(1000);
    await this.countDown(second - 1);
  }

  async created() {
    await this.countDown(this.countDownSecond);
    if (this.$route.path !== '/success') return;
    // this.$router.push('/select');
    this.redirect();
  }
  redirect() {
    this.root.to = this.root.home;
    this.root.redirect(true);
  }
}
</script>
<style lang="less" module>
.content {
  text-align: center;
  font-size: 18px;
  padding: 45px 20px 25px;
}
.countDown {
  color: var(--primary);
}
.footer {
  flex-direction: row-reverse;
}
</style>
