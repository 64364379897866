<template>
  <router-view />
</template>
<script>
import { createModuleUrl } from '@/utils';
import { ssoClient } from '@triascloud/sso-client';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SsoPage extends Vue {
  created() {
    const alreadyLogin = !!ssoClient.getTokenSync();
    alreadyLogin &&
      location.replace(this.$route.query.to || createModuleUrl('app'));
  }
}
</script>
