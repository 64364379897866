import { ssoClient } from '@triascloud/sso-client';
import request from './request';

const serviceName = '/tenant-center/account';

/**
 * @name 发送手机验证码
 * @param { string | number } phone 手机号
 * @param { "loginSms" | "forgot-password" | "registerCodeSms" | "justRegisterCodeSms" } type 业务类型
 */
export function sendVerifyCode(phone, type, query = null) {
  return request(`${serviceName}/${type}/${phone}`, {
    method: 'GET',
    auth: false,
    body: query,
  });
}

/**
 * @name 发送注册手机验证码--附带图形验证码验证
 * @param { 'sms'|'email' } type 账号类型
 * @param { string | number } phone 手机号
 * @param { string  } captcha 图形验证码
 */
export function sendRegisterVerifyCode(type, phone, captcha) {
  return request(`${serviceName}/register/${type}/${phone}/${captcha}`, {
    method: 'GET',
    auth: false,
  });
}

export function getCaptcha(account) {
  return request(`${serviceName}/auth/captcha/${account}`, {
    method: 'GET',
    auth: false,
  });
}

/**
 *
 * @param { 'sms'|'email' } type 账号类型
 * @param {*} body 注册信息
 * @returns
 */
export function registerAccount(type, body) {
  return request(`${serviceName}/register/${type}`, {
    method: 'POST',
    body,
    auth: false,
  });
}

/**
 * @name 重置密码
 * @param { 'sms'|'email'} type 账号类型
 * @param {*} body
 * @returns
 */
export function resetPassword(type, body) {
  return request(`${serviceName}/forgot-password/${type}`, {
    method: 'POST',
    body,
    auth: false,
  });
}

export function loginWithPassword(body, currentTenantId) {
  return request(`${serviceName}/auth/password`, {
    method: 'POST',
    body: parseBody(body, currentTenantId),
    auth: false,
  });
}

export function loginWithVerifyCode(body, currentTenantId) {
  return request(`${serviceName}/auth/phone`, {
    method: 'POST',
    body: parseBody(body, currentTenantId),
    auth: false,
    showError: false,
  });
}

export function loginWithDingTalkCode(code, currentTenantId, inviteKey) {
  return request(`${serviceName}/auth/dingTalk`, {
    method: 'POST',
    body: { code, loginEnterprise: currentTenantId, inviteKey },
    auth: false,
  });
}

export function requestLogout() {
  return request(`${serviceName}/exit`, {
    method: 'POST',
    headers: {
      ['X-Access-Token']: ssoClient.getTokenSync(),
    },
    auth: false,
    showError: false,
  });
}

function parseBody(body, currentTenantId) {
  return {
    ...body,
    captcha: {
      code: body.captcha,
    },
    loginEnterprise: currentTenantId,
    rememberMe: body.rememberMe ? 'YES' : 'NO',
  };
}

/**
 * @name 发送邮箱登录验证码
 * @param { string } email
 * @returns Promise<string>
 */
export function sendVerifyEmailCode(email) {
  return request(`${serviceName}/loginEmail/${email}`, {
    auth: false,
  });
}

/**
 * @name 邮箱验证码登录
 * @param {*} body
 * @param { string } currentTenantId 企业id
 * @returns Promise<string>
 */
export function loginWithEmail(body, currentTenantId) {
  return request(`${serviceName}/auth/email`, {
    method: 'POST',
    body: parseBody(body, currentTenantId),
    auth: false,
  });
}

/**
 * @name 密码输错过多给账号发送短信
 * @param { string } memberId
 * @returns Promise<string>
 */
export function sendVerifyCodeByAccount(memberId) {
  return request(`${serviceName}/verifySms/${memberId}`, { auth: false });
}

/**
 * @name 获取脱敏手机号
 * @param { string } account
 * @returns Promise<{ mobilePhone: string; pkId: string }>
 */
export function getDesensitizedPhone(account) {
  return request(`${serviceName}/mobilePhoneDesensitized/${account}`, {
    auth: false,
  });
}
