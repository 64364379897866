<template>
  <!-- <div
    :class="$style['point-wrapper']"
    :style="`top:${data.y}px;left:${data.x}px;opacity:${data.animation.opacity};transform:translate(${data.animation.x}px,${data.animation.y}px) scale(${data.animation.scale});`"
  >
    <div
      :class="$style.point"
      :style="`width:${data.r}px;height:${data.r}px;transform:translate(${floatX}px,${floatY}px)`"
    />
  </div> -->
  <div
    :class="[$style.point, floating && $style.floating]"
    :style="pointStyle"
  />
</template>
<script>
import { delay } from '@triascloud/utils';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
@Component()
export default class Point extends Vue {
  @Prop({ default: () => ({}), type: Object }) point;

  @Prop({ type: Boolean }) floating;

  get pointStyle() {
    return {
      top: this.point.y + 'px',
      left: this.point.x + 'px',
      opacity: this.point.opacity || 1,
      width: this.point.r + 'px',
      height: this.point.r + 'px',
      ...this.floatingStyle,
    };
  }

  get floatingStyle() {
    if (!this.floating) return null;
    return {
      ['--floatX']: this.floatX + 'px',
      ['--floatY']: this.floatY + 'px',
    };
  }

  floatX = 0;
  floatY = 0;

  @Watch('floating', { immediate: true })
  async handleFloating(floating) {
    if (floating) {
      await delay(1000);
      this.randomFloating();
    } else {
      this.floatX = 0;
      this.floatY = 0;
    }
  }

  async randomFloating() {
    if (!this.floating) return;
    this.floatX = Math.random() * 10 + 5;
    this.floatY = Math.random() * 10 + 5;
    await delay(Math.random() * 800 + 400);
    return this.randomFloating();
  }

  // floatX = 0;
  // floatY = 0;
  // timer = '';

  // @Watch('status')
  // handleStatusChange(status) {
  //   if (status === 'gather') {
  //     this.floatX = 0;
  //     this.floatY = 0;
  //     clearInterval(this.timer);
  //   } else {
  //     this.startDisperseAnimation();
  //   }
  // }
  // startDisperseAnimation() {
  //   this.timer = setInterval(() => {
  //     this.floatX = Math.random() * 5;
  //     this.floatY = Math.random() * 5;
  //   }, 1000);
  // }
}
</script>
<style lang="less" module>
.point {
  position: absolute;
  border-radius: 50%;
  transition-property: color, top, left, width, height, opacity, transform;
  transition-duration: 0.3s, 1s, 1s, 1s, 1s, 1s, 1s;
}
:global(.tint) .point {
  background-color: var(--primary-fade-20);
}
:global(.dark) .point {
  background-color: var(--home-bg);
}
.floating {
  transform: translate(var(--floatX), var(--floatY));
}
</style>
