<template>
  <a-button
    :type="type"
    v-bind="$attrs"
    :loading="loading"
    @click="handleClick"
  >
    <slot />
  </a-button>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Button as AButton } from 'ant-design-vue';

@Component({ components: { AButton } })
export default class AsyncButton extends Vue {
  /** @name 按钮类型 */
  @Prop({ type: String, default: 'default' }) type;
  /** @name 异步点击事件 */
  @Prop({ type: Function, default: () => () => void 0 }) click;

  @Prop({ type: Boolean, default: false }) stop;

  loading = false;
  /** @param { MouseEvent } ev */
  async handleClick(ev) {
    this.stop && ev.stopPropagation();
    this.loading = true;
    try {
      await this.click();
    } finally {
      this.loading = false;
    }
  }
}
</script>
