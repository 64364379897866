import request from './request';

const serviceName = '/oss/backend';

export function getAuth(url) {
  return request(`${serviceName}/getAuth`, {
    method: 'POST',
    body: [url],
    auth: false,
  });
}
