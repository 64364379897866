<template>
  <div></div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { authLogin } from '@/services/oauth';
import { setStorage } from '@/services/storage';
import { createModuleUrl } from '@/utils';
@Component
export default class SsoOauthPage extends Vue {
  async created() {
    const { state, to, response_type } = this.$route.query;
    const codeKey = response_type || 'code';
    const code = this.$route.query[codeKey];
    if (!code || !state) return this.$message.error('参数错误，请检查回调地址');
    try {
      const token = await authLogin('custom', { code, state });
      await setStorage(token, true);
      location.replace(to || createModuleUrl('app'));
    } catch (error) {
      this.$message.error(error?.message || '登录失败，请检查配置');
    }
  }
}
</script>
