let prefix = '';
let host = location.host.split('.').slice(-2).join('.');

// 线上环境去除域名环境前缀
if (process.env.VUE_APP_ENV !== 'production') {
  prefix = process.env.VUE_APP_ENV + '.';
}
// 本地调试使用env文件中的host
if (process.env.NODE_ENV !== 'production') {
  host = process.env.VUE_APP_HOST || host;
}

/**
 * @name 根据功能模块创建地址
 * @param { string } module
 */
export function createModuleUrl(module, protocol = location.protocol) {
  return `${protocol}//${prefix}${module}.${host}`;
}
