import { stringify } from '@triascloud/utils';
import request from './request';

const serviceName = '/tenant-center';

export function getCurrentUser() {
  return request(`${serviceName}/tenant/member`, {
    method: 'GET',
  });
}

/**
 * @typedef Enterprise
 * @property { string } enterpriseName	企业租户名称
 * @property { string } logo	企业logo
 * @property { boolean } mainEnterprise	是否是主企业
 * @property { string } memberStatus	成员状态
 * @property { string } organizationOrigin	组织架构来源
 * @property { string } pkId	租户企业ID
 * @property { string } tenantStatus	租户企业状态
 */

/** @return { Promise<Enterprise[]> } */
export function getTenantList() {
  return request(`${serviceName}/enterprise/memberEnterprise`, {
    method: 'GET',
  });
}

export async function getCurrentUserAndTenant() {
  const [user, tenantList] = await Promise.all([
    getCurrentUser(),
    getTenantList(),
  ]);
  const currentTenantId = user.tenant && user.tenant.tenantId;
  const tenant =
    (currentTenantId &&
      tenantList.find(tenant => tenant.pkId === currentTenantId)) ||
    null;
  return { user, tenant };
}

export function getInviteInfo(inviteKey) {
  return request(`${serviceName}/tenant/member/inviteInfo/${inviteKey}`, {
    method: 'GET',
    auth: false,
  });
}

export function inviteJoin(inviteKey) {
  return request(`${serviceName}/tenant/member/inviteJoin`, {
    method: 'POST',
    body: { inviteKey },
  });
}

export function switchEnterprise(tenantId) {
  return request(
    `${serviceName}/enterprise/switchEnterprise?${stringify({ tenantId })}`,
    {
      method: 'PUT',
    },
  );
}

export function getEnterpriseByUrl(param) {
  return request(`${serviceName}/enterprise/tenantLogo`, {
    body: param,
    auth: false,
  });
}
