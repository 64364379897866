import store from '@/store';
import { crossStorage } from '@triascloud/cross-storage';
import request from './request';

const serviceName = '/configuration/memberHabit';

export function getHabit(key) {
  if (store.state.changed[key]) {
    const value = crossStorage.getItem(key);
    return setHabit(key, value).then(() => value);
  }
  return request(`${serviceName}/get`, {
    method: 'GET',
    body: {
      key,
    },
  });
}

export function setHabit(key, value) {
  return request(`${serviceName}/saveOrUpdate`, {
    method: 'POST',
    body: {
      key,
      value,
    },
  });
}
