<template>
  <div :class="$style.background">
    <svg width="100%" height="100%" :class="$style.def">
      <defs>
        <filter id="blur" filterUnits="userSpaceOnUse">
          <feOffset result="offset" in="SourceAlpha" />
          <feGaussianBlur result="blur" stdDeviation="12" />
          <feFlood result="flood" flood-color="currentColor" />
          <feComposite result="composite" operator="in" in2="blur" />
          <feBlend result="blend" in="SourceGraphic" />
        </filter>
      </defs>
    </svg>
    <rect-chart />
    <rect-radius />
    <triangle />
    <columnar />
    <line-chart />
    <double-circle />
    <circle-chart />
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';

import RectChart from './background-image/rect.vue';
import RectRadius from './background-image/rect-radius.vue';
import Triangle from './background-image/triangle.vue';
import Columnar from './background-image/columnar.vue';
import DoubleCircle from './background-image/double-circle.vue';
import LineChart from './background-image/line-chart.vue';
import CircleChart from './background-image/circle.vue';

@Component({
  components: {
    RectChart,
    RectRadius,
    Triangle,
    Columnar,
    DoubleCircle,
    LineChart,
    CircleChart,
  },
})
export default class AnimationBackground extends Vue {}
</script>
<style lang="less" module>
.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  > svg:not(.def) {
    animation: floating 2s infinite alternate;
  }
}

:global(.tint) {
  .background {
    background-image: linear-gradient(
      -45deg,
      var(--primary-fade-10),
      var(--primary-fade-30)
    );
    > svg:not(.def) {
      color: var(--primary-20);
    }
  }
  .def {
    color: var(--primary-fade-12);
  }
}

:global(.dark) {
  .background {
    background-color: var(--body-bg);
    > svg:not(.def) {
      color: var(--body-bg);
    }
  }
  .def {
    color: rgba(17, 17, 17, 0.3);
  }
}
@keyframes floating {
  from {
  }
  to {
    transform: translate(15px, -12px);
  }
}
</style>
