<template>
  <div :class="[pageStyle.page, $style.register]">
    <div :class="pageStyle.pageHeader">{{ $t('reset.retrievePassword') }}</div>
    <a-form-model
      :class="pageStyle.pageBody"
      :model="model"
      :rules="rules"
      ref="form"
      hide-required-mark
    >
      <a-form-model-item :label="$t('common.phone')" prop="phone">
        <x-input
          :maxLength="11"
          autocomplete="tel"
          v-model="model.phone"
          name="phone"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('common.code')"
        prop="code"
        :class="pageStyle.verifyCode"
      >
        <x-input
          autocomplete="off"
          v-model="model.code"
          name="code"
          :maxLength="6"
        />
        <async-button
          ghost
          type="primary"
          :click="sendCode"
          :disabled="!!countDownSecond"
        >
          {{
            countDownSecond
              ? `${countDownSecond}S`
              : firstGetCode
              ? $t('login.getCode')
              : $t('login.resend')
          }}
        </async-button>
      </a-form-model-item>
      <a-form-model-item :label="$t('reset.newPassword')" prop="newPassword">
        <x-input
          autocomplete="new-password"
          :maxLength="20"
          v-model="model.newPassword"
          name="newPassword"
          @input="checkConfirmPassword"
          type="password"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('reset.confirmPassword')"
        prop="confirmPassword"
      >
        <x-input
          autocomplete="new-password"
          :maxLength="20"
          v-model="model.confirmPassword"
          name="confirmPassword"
          type="password"
        />
      </a-form-model-item>
      <async-button block ghost type="primary" :click="handleReset">
        {{ $t('reset.confirmChange') }}
      </async-button>
    </a-form-model>
    <div :class="[pageStyle.pageFooter, $style.footer]">
      <div>
        <span>{{ $t('reset.thinkPassword') }}</span>
        <router-link to="/">{{ $t('login.login') }}</router-link>
      </div>
      <div>
        {{ $t('reset.forgotEmail')
        }}<router-link to="/reset/email">{{
          $t('reset.resetByEmail')
        }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Inject } from 'vue-property-decorator';
import { match, delay, requiredRule } from '@triascloud/utils';
import { validateFieldAsync } from '@/utils';
import AsyncButton from '@/components/async-button';
import { sendVerifyCode, resetPassword } from '@/services/account';
import { SSOContext } from '../context';
@Component({
  components: { AsyncButton },
})
export default class RegisterPage extends Vue {
  @Inject(SSOContext.PageStyle) pageStyle;
  model = {
    phone: '',
    code: '',
    newPassword: '',
    confirmPassword: '',
  };
  countDownSecond = 0;
  firstGetCode = true;
  get rules() {
    return {
      phone: [
        {
          required: true,
          message: this.$t('valid.input', {
            value: this.$t('common.phone'),
          }),
        },
        {
          pattern: match.REG_PHONE,
          message: this.$t('valid.phone'),
          trigger: 'blur',
        },
      ],
      code: {
        required: true,
        message: this.$t('valid.required', {
          value: this.$t('common.code'),
        }),
      },
      newPassword: [
        requiredRule(
          this.$t('valid.required', {
            value: this.$t('common.password'),
          }),
        ),
        {
          pattern: match.REG_STRONG_PASSWORD,
          message: this.$t('valid.strongPassword'),
          trigger: 'blur',
        },
      ],
      confirmPassword: [
        requiredRule(
          this.$t('valid.required', {
            value: this.$t('common.password'),
          }),
        ),
        {
          message: this.$t('valid.passwordInconsistency'),
          validator: (_, value) => value === this.model.newPassword,
        },
        {
          pattern: match.REG_STRONG_PASSWORD,
          message: this.$t('valid.strongPassword'),
          trigger: 'blur',
        },
      ],
    };
  }
  async sendCode() {
    await validateFieldAsync(this.$refs.form, 'phone');
    const result = await sendVerifyCode(
      this.model.phone,
      'forgot-password/sms',
    );
    if (typeof result === 'string' && /^\d{6}$/.test(result)) {
      this.model.code = result;
      validateFieldAsync(this.$refs.form, 'code');
    }
    this.countDown(60);
    if (this.firstGetCode) this.firstGetCode = false;
  }

  async countDown(second) {
    this.countDownSecond = second;
    if (second <= 0) return;
    await delay(1000);
    this.countDown(second - 1);
  }

  async handleReset() {
    await this.$refs.form.validate();
    await resetPassword('sms', this.model);
    this.$message.success(this.$t('reset.modifySuccess'));
    this.$router.push('/');
  }

  checkConfirmPassword() {
    this.$refs.form.validateField('confirmPassword');
  }
}
</script>
<style lang="less" module>
div.register {
  letter-spacing: 1px;
}

.agree :global(.ant-form-item-control) {
  text-align: right;
  :global(.ant-checkbox-wrapper) {
    color: var(--font-info);
  }
  :global(.ant-form-explain) {
    bottom: -12px;
  }
}
.footer {
  justify-content: space-between;
  flex-direction: row;
  border-top: 1px solid var(--border);
}
</style>
