import request from './request';

const serviceName = '/tenant-center/custom-oauth';

/** @name 获取第三方请求对象 */
export function getOauth(type) {
  return request(`${serviceName}/sso?id=${type}`, {
    method: 'GET',
    auth: false,
  });
}
