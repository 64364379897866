<template>
  <a-form-model
    :class="[pageStyle.pageBody, authBindStyle.form]"
    ref="form"
    :model="model"
    :rules="rules"
    hide-required-mark
    @submit="handleLoginBind"
  >
    <a-form-model-item prop="phone">
      <x-input
        v-model="model.phone"
        name="phone"
        :maxLength="11"
        :placeholder="$t('valid.input', { value: $t('common.phone') })"
        autocomplete="tel"
      >
        <x-icon slot="prefix" type="tc-icon-mobile" />
      </x-input>
    </a-form-model-item>
    <a-form-model-item prop="verifyCode">
      <x-input
        v-model="model.verifyCode"
        name="verifyCode"
        :maxLength="6"
        :placeholder="$t('valid.input', { value: $t('common.code') })"
        autocomplete="off"
      >
        <x-icon slot="prefix" type="tc-icon-mail" />
        <async-button
          type="link"
          slot="suffix"
          :disabled="!!countDownSecond"
          :click="sendCode"
        >
          {{
            countDownSecond
              ? `${countDownSecond}S`
              : firstGetCode
              ? $t('login.getCode')
              : $t('login.resend')
          }}
        </async-button>
      </x-input>
    </a-form-model-item>
    <a-form-model-item prop="captcha" v-if="needCaptcha">
      <x-input
        v-model="model.captcha"
        name="captcha"
        :placeholder="$t('valid.input', { value: $t('common.captcha') })"
        autocomplete="off"
      >
        <x-icon slot="prefix" type="tc-icon-mail" />
        <img
          slot="suffix"
          :src="captcha"
          :class="$style.captcha"
          @click="reloadCaptcha"
        />
      </x-input>
    </a-form-model-item>
    <router-link to="/auth-bind/account" :class="authBindStyle.toChange">
      {{ $t('login.bind', { type: $t('oauth.user') }) }}
    </router-link>
    <async-button block type="primary" html-type="submit">
      {{ $t('login.andBind') }}
    </async-button>
    <div :class="authBindStyle.noAccount">
      {{ $t('oauth.noAccount') }}
      <router-link to="/auth-bind/register" :class="authBindStyle.register">{{
        $t('oauth.register')
      }}</router-link>
    </div>
  </a-form-model>
</template>
<script>
import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import { match, delay } from '@triascloud/utils';
import { validateFieldAsync } from '@/utils';
import { ResponseError } from '@/enum';
import { getCaptcha, sendVerifyCode } from '@/services/account';
import { bindPhone } from '@/services/oauth';
import AsyncButton from '@/components/async-button';
import { SSOContext } from '@/views/context';

@Component({
  components: { AsyncButton },
})
export default class AuthBindPhonePage extends Vue {
  @Inject(SSOContext.Root) root;
  @Inject(SSOContext.PageStyle) pageStyle;
  @Inject(SSOContext.AuthBindStyle) authBindStyle;
  @Prop({ type: String, default: '' }) code;
  @Prop({ type: String, default: '' }) state;
  countDownSecond = 0;
  needCaptcha = false;
  firstGetCode = true;
  captcha = '';
  model = {
    phone: '',
    verifyCode: '',
    captcha: '',
  };

  get rules() {
    return {
      phone: [
        {
          required: true,
          message: this.$t('valid.input', { value: this.$t('common.phone') }),
        },
        {
          pattern: match.REG_PHONE,
          message: this.$t('valid.phone'),
          trigger: 'blur',
        },
      ],
      captcha: {
        required: true,
        message: this.$t('valid.input', { value: this.$t('common.captcha') }),
      },
      verifyCode: [
        {
          required: true,
          message: this.$t('valid.required', {
            value: this.$t('common.code'),
          }),
        },
        {
          len: 6,
          message: this.$t('valid.code'),
          trigger: 'blur',
        },
      ],
    };
  }

  async reloadCaptcha() {
    if (!this.needCaptcha) return;
    this.captcha = await getCaptcha(this.model.phone);
  }

  async sendCode() {
    await validateFieldAsync(this.$refs.form, 'phone');
    const result = await sendVerifyCode(this.model.phone, 'loginSms');
    if (typeof result === 'string' && /^\d{6}$/.test(result)) {
      this.model.verifyCode = result;
      validateFieldAsync(this.$refs.form, 'verifyCode');
    }
    this.countDown(60);
    if (this.firstGetCode) this.firstGetCode = false;
  }

  async countDown(second) {
    this.countDownSecond = second;
    if (second <= 0) return;
    await delay(1000);
    this.countDown(second - 1);
  }

  async handleLoginBind(ev) {
    ev && ev.preventDefault();
    await this.$refs.form.validate();
    try {
      const payload = this.root.authBindPayload;
      const token = await bindPhone(payload.type, payload.uuid, this.model);
      this.$message.success(this.$t('login.loginSuccess'));
      await this.root.setStorage(token, true);
      this.root.redirect();
    } catch (error) {
      if (!error) return;
      switch (error.code) {
        case ResponseError.LOGIN_NEED_CAPTCHA:
        case ResponseError.LOGIN_CAPTCHA_ERROR:
          this.needCaptcha = true;
          this.$message.error(error.message);
          this.reloadCaptcha();
          break;
        case ResponseError.LOGIN_PASSWORD_ERROR:
          this.$message.error(error.message);
          this.reloadCaptcha();
          break;
        case ResponseError.LOGIN_BIND_ERROR:
          this.$emit('bind', true);
          break;
        default:
          this.$message.error(error.message);
          throw error;
      }
    }
  }
}
</script>
<style lang="less" module>
.captcha {
  width: 70px;
  height: 30px;
}
</style>
