import request from './request';

const serviceName = '/tenant-center/oauth';

/** @name 获取第三方请求对象 */
export function getOauth(type) {
  return request(`${serviceName}/${type}`, {
    method: 'GET',
    auth: false,
  });
}

/** @name 授权登录 */
export function authLogin(type, params) {
  return request(`${serviceName}/${type}`, {
    method: 'POST',
    auth: false,
    showError: false,
    body: params,
  });
}

/** @name 账号密码绑定授权 */
export function bindPassword(type, unionId, params) {
  return request(`${serviceName}/password/${type}/${unionId}`, {
    method: 'POST',
    auth: false,
    showError: false,
    body: params,
  });
}

/** @name 手机号码绑定授权 */
export function bindPhone(type, unionId, params) {
  return request(`${serviceName}/phone/${type}/${unionId}`, {
    method: 'POST',
    auth: false,
    showError: false,
    body: params,
  });
}

/** @name 注册账号绑定授权 */
export function bindRegister(type, unionId, params) {
  return request(`${serviceName}/register/${type}/${unionId}`, {
    method: 'POST',
    auth: false,
    body: params,
  });
}
