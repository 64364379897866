<template>
  <div :class="[pageStyle.page, $style.register]">
    <div :class="$style.registerHeader">{{ $t('register.title') }}</div>
    <a-form-model
      :class="[pageStyle.pageBody, $style.registerBody]"
      :model="model"
      :rules="rules"
      ref="form"
      hide-required-mark
    >
      <a-form-model-item :label="$t('common.phone')" prop="phone">
        <x-input
          autocomplete="tel"
          v-model="model.phone"
          name="phone"
          :maxLength="11"
          @blur="reloadCaptcha"
        />
      </a-form-model-item>
      <a-form-model-item
        v-if="captcha"
        prop="captcha"
        :label="$t('common.captcha')"
        :class="[pageStyle.verifyCode, $style.verifyCode]"
      >
        <x-input
          v-model="model.captcha"
          name="captcha"
          :placeholder="$t('valid.input', { value: $t('common.captcha') })"
          autocomplete="off"
        >
        </x-input>
        <img
          :class="$style.verifyCodeBtn"
          :src="captcha"
          alt=""
          @click="reloadCaptcha"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('common.code')"
        prop="verifyCode"
        :class="[pageStyle.verifyCode, $style.verifyCode]"
      >
        <x-input
          autocomplete="off"
          v-model="model.verifyCode"
          name="verifyCode"
          :maxLength="6"
        />
        <async-button
          ghost
          type="primary"
          :class="$style.verifyCodeBtn"
          :click="sendCode"
          :disabled="!!countDownSecond"
        >
          {{
            countDownSecond
              ? `${countDownSecond}S`
              : firstGetCode
              ? $t('login.getCode')
              : $t('login.resend')
          }}
        </async-button>
      </a-form-model-item>
      <a-form-model-item :label="$t('common.inviteCode')" prop="inviteCode">
        <x-input
          autocomplete="off"
          v-model="model.inviteCode"
          name="inviteCode"
          :maxLength="7"
        />
      </a-form-model-item>
      <a-form-model-item prop="agree" :class="$style.agree">
        <a-checkbox v-model="model.agree" name="agree">
          <span>{{ $t('register.agree') }}</span>
          <a target="_blank" href="/contract">{{ $t('register.terms') }}</a
          >{{ $t('register.and') }}
          <a target="_blank" href="/privacy">{{ $t('register.privacy') }}</a>
        </a-checkbox>
      </a-form-model-item>
      <async-button block ghost type="primary" :click="handleRegister">
        {{ $t('register.title') }}
      </async-button>
    </a-form-model>
    <div :class="[pageStyle.pageFooter, $style.footer]">
      <div>
        <span>{{ $t('register.hasAccount') }}</span>
        <router-link to="/">{{ $t('login.login') }}</router-link>
      </div>
      <div>
        <span>{{ $t('register.cannot', { type: $t('common.phone') }) }}</span>
        <router-link to="/register/email">{{
          $t('register.here')
        }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Inject } from 'vue-property-decorator';
import { match, delay } from '@triascloud/utils';
import { validateFieldAsync } from '@/utils';
import AsyncButton from '@/components/async-button';
import {
  sendRegisterVerifyCode,
  registerAccount,
  getCaptcha,
} from '@/services/account';
import { SSOContext } from '../context';

@Component({
  components: { AsyncButton },
})
export default class RegisterPage extends Vue {
  @Inject(SSOContext.PageStyle) pageStyle;
  @Inject(SSOContext.Root) root;
  model = {
    phone: '',
    verifyCode: '',
    inviteCode: '',
    agree: false,
    captcha: '',
  };
  countDownSecond = 0;
  firstGetCode = true;
  captcha = '';
  get rules() {
    return {
      phone: [
        {
          required: true,
          message: this.$t('valid.input', { value: this.$t('common.phone') }),
        },
        {
          pattern: match.REG_PHONE,
          message: this.$t('valid.phone'),
          trigger: 'blur',
        },
      ],
      verifyCode: [
        {
          required: true,
          message: this.$t('valid.required', {
            value: this.$t('common.code'),
          }),
        },
        {
          pattern: match.REG_PURE_NUMBER,
          message: this.$t('valid.code'),
        },
      ],
      captcha: [
        {
          required: true,
          message: this.$t('valid.input', { value: this.$t('common.captcha') }),
        },
      ],
      // 邀请码非必填
      // inviteCode: [
      //   {
      //     required: true,
      //     message: this.$t('valid.input', {
      //       value: this.$t('common.inviteCode'),
      //     }),
      //   },
      //   {
      //     pattern: /^[A-Za-z0-9]{6}/,
      //     message: this.$t('valid.inviteCode'),
      //     trigger: 'blur',
      //   },
      // ],
    };
  }
  async sendCode() {
    // await this.$nextTick();
    if (!this.captcha) return;
    await validateFieldAsync(this.$refs.form, 'phone');
    await validateFieldAsync(this.$refs.form, 'captcha');
    const result = await sendRegisterVerifyCode(
      'sms',
      this.model.phone,
      this.model.captcha,
    );
    if (typeof result === 'string' && /^\d{6}$/.test(result)) {
      this.model.verifyCode = result;
      validateFieldAsync(this.$refs.form, 'verifyCode');
    }
    this.countDown(60);
    if (this.firstGetCode) this.firstGetCode = false;
  }

  async countDown(second) {
    this.countDownSecond = second;
    if (second <= 0) return;
    await delay(1000);
    this.countDown(second - 1);
  }

  async reloadCaptcha() {
    await validateFieldAsync(this.$refs.form, 'phone');
    this.captcha = await getCaptcha(this.model.phone);
    this.model.captcha = '';
  }

  async handleRegister() {
    // await this.$refs.form.validate();
    await validateFieldAsync(this.$refs.form, ['phone', 'verifyCode']);
    if (!this.model.agree) {
      this.$message.error(this.$t('valid.agree'));
      return;
    }
    const token = await registerAccount('sms', {
      ...this.model,
      inviteKey: this.root.inviteKey, // 企业邀请码
    });
    await this.root.setStorage(token, true);
    this.root.to = this.root.home;
    this.$router.push('/success');
    // this.root.redirect();
  }
}
</script>
<style lang="less" module>
div.register {
  letter-spacing: 1px;
  width: 440px;
  :global {
    .x-input,
    .ant-input {
      height: 32px;
    }
  }
}

.agree :global(.ant-form-item-control) {
  text-align: right;
  :global(.ant-checkbox-wrapper) {
    color: var(--font-info);
    text-align: left;
  }
  :global(.ant-form-explain) {
    bottom: -12px;
  }
  :global(.ant-checkbox + span) {
    padding-right: 0;
  }
}
.footer {
  justify-content: space-between;
  border-top: 1px solid var(--border);
}
.registerBody {
  padding: 20px;
}
.registerHeader {
  border-bottom: 1px solid var(--form-border);
  padding: 14px 20px;
  font-weight: bold;
  font-size: 16px;
}
.verifyCode .verifyCodeBtn {
  width: 110px;
  margin-left: 10px;
  height: 32px;
  cursor: pointer;
}
.captcha {
  right: 5px;
  .captchaImage {
    width: 110px;
    height: 32px;
    border: 1px solid var(--border);
    cursor: pointer;
  }
}
</style>
