<template>
  <a-form-model
    :class="[pageStyle.pageBody, authBindStyle.form]"
    ref="form"
    :model="model"
    :rules="rules"
    hide-required-mark
    @submit="handleLoginBind"
  >
    <a-form-model-item prop="account">
      <x-input
        v-model="model.account"
        name="account"
        :placeholder="$t('common.username') + '/' + $t('common.phone')"
        autocomplete="username"
        :maxLength="20"
      >
        <x-icon slot="prefix" type="tc-icon-user" />
      </x-input>
    </a-form-model-item>
    <a-form-model-item prop="password">
      <x-input
        v-model="model.password"
        name="password"
        :placeholder="$t('common.password')"
        autocomplete="current-password"
        :maxLength="20"
        type="password"
      >
        <x-icon slot="prefix" type="tc-icon-lock" />
      </x-input>
    </a-form-model-item>
    <a-form-model-item prop="captcha" v-if="needCaptcha">
      <x-input
        v-model="model.captcha"
        autocomplete="off"
        name="captcha"
        :placeholder="$t('valid.input', { value: $t('common.captcha') })"
      >
        <x-icon slot="prefix" type="tc-icon-mail" />
        <img
          slot="suffix"
          :src="captcha"
          :class="$style.captcha"
          @click="reloadCaptcha"
        />
      </x-input>
    </a-form-model-item>
    <router-link to="/auth-bind/phone" :class="authBindStyle.toChange">
      {{ $t('login.bind', { type: $t('common.code') }) }}
    </router-link>
    <async-button block type="primary" html-type="submit">
      {{ $t('login.andBind') }}
    </async-button>
    <div :class="authBindStyle.noAccount">
      {{ $t('oauth.noAccount') }}
      <router-link to="/auth-bind/register" :class="authBindStyle.register">{{
        $t('oauth.register')
      }}</router-link>
    </div>
  </a-form-model>
</template>
<script>
import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import { ResponseError } from '@/enum';
import { getCaptcha } from '@/services/account';
import { bindPassword } from '@/services/oauth';
import AsyncButton from '@/components/async-button';
import { SSOContext } from '@/views/context';
import { match } from '@triascloud/utils';

@Component({
  components: { AsyncButton },
})
export default class AuthBindAccountPage extends Vue {
  @Inject(SSOContext.Root) root;
  @Inject(SSOContext.PageStyle) pageStyle;
  @Inject(SSOContext.AuthBindStyle) authBindStyle;
  @Prop({ type: String, default: '' }) code;
  @Prop({ type: String, default: '' }) state;
  needCaptcha = false;
  captcha = '';
  model = {
    phone: '',
    password: '',
    captcha: '',
  };

  get rules() {
    return {
      account: {
        required: true,
        message: this.$t('valid.required', {
          value: this.$t('common.account'),
        }),
      },
      captcha: {
        required: true,
        message: this.$t('valid.input', { value: this.$t('common.captcha') }),
      },
      password: [
        {
          required: true,
          message: this.$t('valid.required', {
            value: this.$t('common.password'),
          }),
        },
        {
          pattern: match.REG_PASSWORD,
          message: this.$t('valid.password'),
          trigger: 'blur',
        },
      ],
    };
  }

  async reloadCaptcha() {
    if (!this.needCaptcha) return;
    this.captcha = await getCaptcha(this.model.account);
  }

  async handleLoginBind(ev) {
    ev && ev.preventDefault();
    await this.$refs.form.validate();
    try {
      const payload = this.root.authBindPayload;
      const token = await bindPassword(payload.type, payload.uuid, this.model);
      this.$message.success(this.$t('login.loginSuccess'));
      await this.root.setStorage(token, true);
      this.root.redirect();
    } catch (error) {
      if (!error) return;
      switch (error.code) {
        case ResponseError.LOGIN_NEED_CAPTCHA:
        case ResponseError.LOGIN_CAPTCHA_ERROR:
          this.needCaptcha = true;
          this.$message.error(error.message);
          this.reloadCaptcha();
          break;
        case ResponseError.LOGIN_PASSWORD_ERROR:
          this.$message.error(error.message);
          this.reloadCaptcha();
          break;
        case ResponseError.LOGIN_BIND_ERROR:
          this.$emit('bind', true);
          break;
        default:
          this.$message.error(error.message);
          throw error;
      }
    }
  }
}
</script>
<style lang="less" module>
.captcha {
  width: 70px;
  height: 30px;
}
</style>
