<template>
  <div :class="[pageStyle.page, $style.bind]">
    <x-icon
      type="tc-icon-back-line"
      :class="$style.back"
      @click="$router.push('/')"
    />
    <div :class="$style.main">
      <div :class="$style.info">
        <div :class="$style.title">{{ $t('oauth.title') }}</div>
        <div :class="$style.logo">
          <x-icon
            theme="filled"
            :type="currentIcon.icon"
            :style="currentIcon.style"
            :class="$style['third-party-icon']"
          />
          <a-icon type="swap" :class="$style.swap" />
          <span :class="$style.triascloud">
            <x-icon type="tc-icon-triascloud" />
          </span>
        </div>
        <div
          :class="$style.warn1"
          v-html="
            $t('oauth.perfectInfo', {
              thirdParty: $t(`oauth[${payload.type}]`),
            })
          "
        />
        <!-- 输入的账号已被其他账号绑定 -->
        <div :class="$style.warn2" v-if="isBind">
          <span :class="$style.danger"
            >{{
              $t('oauth.bound', { thirdParty: $t(`oauth[${payload.type}]`) })
            }}
          </span>
          <router-link to="/" :class="$style.active">{{
            $t('login.loginDirect')
          }}</router-link>
        </div>
      </div>
      <keep-alive>
        <router-view
          :authBindStyle="$style"
          :code="code"
          :state="state"
          @bind="val => (isBind = val)"
        />
      </keep-alive>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Inject, Provide } from 'vue-property-decorator';
import { SSOContext } from '@/views/context';

@Component()
export default class AuthBindPage extends Vue {
  @Inject(SSOContext.Root) root;
  @Inject(SSOContext.PageStyle) pageStyle;
  @Provide(SSOContext.AuthBindStyle)
  get authBindStyle() {
    return this.$style;
  }
  get code() {
    return this.$route.query.code || '';
  }
  get state() {
    return this.$route.query.state || '';
  }
  get payload() {
    return this.root.authBindPayload;
  }
  get iconList() {
    return [
      {
        source: 'wechatopen',
        icon: 'tc-icon-wechat-filled',
        style: { color: '#00c900' },
      },
      {
        source: 'alipay',
        icon: 'alipay-circle',
        style: { color: '#06B4FD' },
      },
      {
        source: 'qq',
        icon: 'qq-circle',
        style: { color: '#30a5dd' },
      },
    ];
  }
  get currentIcon() {
    return this.iconList.filter(item => item.source === this.payload.type)[0];
  }
  isBind = false;
}
</script>
<style lang="less" module>
div.bind {
  min-width: 730px;
  text-align: center;
  font-size: 16px;
  padding: 22px;
  .back {
    position: absolute;
    color: var(--steps-bg);
    font-size: 17px;
    cursor: pointer;
  }
  :global .strong {
    color: var(--font-sub);
  }
}
.form {
  padding: 25px 0 0;
  overflow-y: auto;
  :global {
    .ant-form-item:not(:last-child) {
      margin-bottom: 30px;
    }
    .ant-input {
      height: 40px;
      &::placeholder {
        color: #ccc;
      }
    }
    .ant-btn-primary {
      height: 40px;
    }
    .ant-input-prefix,
    .ant-input-suffix {
      .anticon {
        font-size: 16px;
        color: #ccc;
      }
    }
    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding-left: 40px;
    }
    .ant-form-explain {
      text-align: left;
      font-size: 12px;
    }
  }
}
.toChange {
  color: var(--font-info);
  font-size: 14px;
  float: right;
  margin: -15px 0 18px;
  cursor: pointer;
}
.noAccount {
  font-size: 14px;
  color: var(--font-color-secondary);
  margin-top: 26px;
}
.register {
  color: var(--font-active);
  cursor: pointer;
}
.main {
  width: 409px;
  margin: 0 auto;
  .title {
    font-size: 18px;
    margin: 15px 0 10px;
  }
  .warn1 {
    font-size: 14px;
    color: var(--font-info);
    margin: 8px 0 5px;
  }
  .warn2 {
    font-size: 12px;
    .danger {
      color: var(--delete);
    }
    .active {
      color: var(--font-active);
      cursor: pointer;
    }
  }
}
.logo {
  display: flex;
  align-content: center;
  justify-content: center;
}
.third-party-icon {
  font-size: 35px;
}
.swap {
  color: #999999;
  font-size: 16px;
  margin: auto 21px;
}
.triascloud {
  display: inline-flex;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #999999;
  align-items: center;
  justify-content: center;
  > i {
    font-size: 30px;
    color: #ffffff;
  }
}
</style>
