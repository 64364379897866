<template>
  <index-page>
    <error-page type="404" :class="$style.notFound" />
  </index-page>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { ErrorPage } from '@triascloud/x-blocks';
import IndexPage from './index.vue';

@Component({
  components: { ErrorPage, IndexPage },
})
export default class NotFoundPage extends Vue {}
</script>
<style lang="less" module>
.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
