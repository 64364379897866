<template>
  <contract-page>
    <pre>
      <h1>三叠云隐私协议</h1>
      更新日期：2023年05月10日
      生效日期：2022年02月23日
      三叠云是一款无代码开发平台，广州三叠纪元智能科技有限公司（简称“我们”）作为三叠云的开发与运营者，深知个人信息对用户（“您”）的重要性，我们将按照法律法规的规定，保护您的个人信息及隐私安全。我们制定本《三叠云隐私协议》（“本隐私政策”）并特别提示：希望您在使用三叠云及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。 适用范围：本隐私政策适用于您通过三叠云移动端、三叠云微信公众号以及三叠云微信小程序、三叠云系统、供第三方网站和应用程序使用的三叠云开发工具包（SDK）和应用程序编程接口（API）方式来访问和使用我们的产品和服务。 请您在使用我们提供的各项产品/服务前，仔细阅读并充分理解本《隐私协议》。一旦您使用或继续使用我们的产品/服务时，即意味着您同意我们按照本隐私协议方式使用您的相关信息。
      <h3>一、我们如何收集和使用个人信息 我们可能在以下几种情形下收集您的信息</h3>
      1.1 注册、登录
      当您注册、登录三叠云及相关服务时，您需要向我们提供您的手机号、密码，以便于我们为您创建帐号，并帮助您完成注册。您还可以根据自身需求选择上传头像、填写组织、职业、性别、以及绑定邮箱来完善您的账号信息。您也可以使用第三方帐号（如微信、QQ、支付宝）登录并使用三叠云，您将授权我们获取您在第三方平台注册的公开信息（头像、昵称以及您授权的其他信息），用于与三叠云帐号绑定，使您可以直接登录并使用本产品和相关服务。如果您使用微信登录或将您的账号与微信绑定，我们还将收集您的微信OpenID。在使用第三方账号进行登录时，可能需要将实现登录所必需的信息在剪切板中写入与读取。这些信息仅供实现登录相关的目的所使用。
      1.2 创建、加入、管理组织
      1.2.1 创建、加入组织
      当您创建组织的时候，您需要向我们提供您的组织名称、您的职位、管理员邮箱以及预计人数。您还可以选择向我们提供您的组织logo、所在地、所在行业、二级域名以及工作地点，以完善您的组织信息。当您选择加入组织时，您需要向我们提供您想要加入的组织ID。
      1.2.2 添加组织成员
      当您添加组织成员时，您需要向我们提供成员姓名和手机号码，您也可以向我们提供新增成员的添加方式、所属部门、职位、工作地点、工号以及工作电话，以完善其个人信息。如果您通过邀请方式添加成员，我们还会收集被邀请人的手机号或邮箱。
      1.2.3 创建部门
      当您创建部门时，您需要向我们提供您所创建的部门名称以及上级部门名称。
      1.2.4 发票设置
      如果您选择设置发票，您需要向我们提供您所在组织的税务登记号、邮寄地址、发票接收人、邮编、电话、收集、邮箱地址、邮箱接收人、传真以及发票类型（普票、专票）。
      1.2.5 权限管理
      当您管理权限时，您需要选择企业应用管理员、企业账户管理员以及超级管理员所对应的成员，以便于实现权限管理。此外，我们还会收集您的权限管理日志信息（操作人姓名、操作时间、角色权限以及对应的成员姓名）。
      1.3 创建、管理应用
      1.3.1 创建应用
      当您创建应用时，您需要向我们提供工作表名称、表单设计信息（控件名称、控件描述以及附件），以便于我们帮助您创建应用。
      1.3.2 创建记录
      当您在应用中创建记录时，您需要向我们提交记录名称、描述以及您想要上传的文件，以便于我们帮助您创建记录仪。
      1.3.3 管理应用
      当您在应用中配置应用的角色、成员和权限时，我们会收集应用管理员的姓名、应用成员、对该应用拥有只读权限的成员以及其他您想要添加的角色名称以及对应的描述、分发的应用项以及权限。
      1.4 创建任务、上传文件
      1.4.1 创建任务
      当您创建任务时，您需要向我们提供您的任务归属、项目名称、任务名称、任务负责人、任务参与者、设置时间、描述和附件，以便于我们为您创建任务。
      1.4.2 上传文件
      当您上传文件时，我们会收集您向我们上传的信息，以便于帮助您实现上传并管理文件的目的。
      1.5 消息通知
      您知悉并同意，对于您在使用产品及/或服务的过程中提供的您的联系方式（联系电话和邮箱），我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证、向您介绍我们的产品和活动等用途。您可以通过我们向您发送的短信或邮件中所提示的方式拒绝接收我们向您发送的营销信息，或通过“联系我们”部分所列方式与我们联络。
      1.6 搜索功能
      当您使用搜索功能时，我们会收集您的搜索记录，以便更好地向您展示和您的搜索内容匹配的搜索记录。
      1.7 运营与安全运行
      1.7.1 运营与安全保障
      我们致力于为您提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是我们的核心目标。出于向您正常提供服务的目的，维护产品和服务的稳定运营，我们将收集您在产品使用过程中产生的使用日志。
      1.7.2 设备信息与日志信息
      a.为了保障软件与服务的安全运行、运营的质量及效率，我们会收集您的硬件型号、操作系统版本号、国际移动设备识别码、唯一设备标识符、网络设备硬件地址、 IP地址、WLAN接入点、蓝牙、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。
      b.为了预防恶意程序、保障运营质量及效率，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。
      c.我们可能使用您的账户信息、设备信息、服务日志信息以及我们关联方、合作方在获得您授权或依法可以共享的信息，用于判断账户安全、进行身份验证、检测及防范安全事件。
      1.8 统计分析
      为了分析应用新增、激活、留存、性能等统计性指标，我们可能会调用剪切板对相关统计信息进行归因分析，请您放心，对此我们不会收集您的隐私信息。
      1.9 收集、使用个人信息目的变更
      请您了解，随着我们业务的发展，可能会对三叠云的功能和提供的服务有所调整变化。原则上，当新功能或服务与我们当前提供的功能或服务相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用您的个人信息，会再次进行告知，并征得您的同意。 特别提示：如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。
      <h3>二、我们如何使用Cookie等同类技术</h3>
      Cookie和设备信息标识等同类技术是互联网中普遍使用的技术。当您使用三叠云及相关服务时，我们可能会使用相关技术向您的设备发送一个或多个Cookie 或匿名标识符，以收集、标识您访问、使用本产品时的信息。我们承诺，不会将Cookie 用于本隐私政策所述目的之外的任何其他用途。我们使用Cookie 和同类技术主要为了实现以下功能或服务：
      2.1 保障产品与服务的安全、高效运转
      我们可能会设置认证与保障安全性的Cookie或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。
      2.2 帮助您获得更轻松的访问体验
      使用此类技术可以帮助您省去重复您填写个人信息、输入搜索内容的步骤和流程（示例：记录搜索、表单填写）。
      2.3 Cookie的清除
      大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。如您进行清除，您可能无法使用由我们提供的、依赖于Cookie的服务或相应功能。
      <h3>三、我们如何共享、转让、公开披露个人信息</h3>
      在未获取您明确同意的情况下，我们不会与其他组织和个人共享、转让、披露您的用户信息，另外，为了提升我们的产品及服务质量或向您提供全新的产品及服务，以下情况除外：（个人信息包括：姓名、手机号码、用户密码、照片、常用地址、通讯地址、位置信息评价信息、设备信息、IP地址等）
      3.1 我们可能将您的信息与我们的关联公司、第三方服务提供商、服务商及代理商等合作伙伴共享，仅用作下列用途：
      3.1.1 提供“一、我们如何收集您的信息”中的相应功能或服务所必需，以及出于“一、我们如何使用收集的信息”中的部分所述目的所必需。
      3.1.2 履行我们在《三叠云服务条款》或本条款中的义务、行使我们的权利。
      3.1.3 为提供更好的服务，我们可能委托第三方服务提供商、服务商及代理（统称“合作伙伴”）向您提供服务，包括但不限于客户服务、技术服务等，因此，为向您提供服务所必需，我们会与合作伙伴共享您的某些信息。例如，为完成身份验证等。
      3.1.4 对于由我们与合作伙伴共同研发、提供的产品、服务，为实现您使用该产品、服务的目的，我们可能需要向合作伙伴共享您的部分信息。如我们与上述任何第三方共享您的信息，我们仅会基于合法、正当、必要原则，在为提供服务所必需的范围内与其共享您的信息，并且我们将努力确保第三方在使用您的信息时遵守本条款及我们要求其遵守的其他适当的保密和安全措施，承诺不得将您的信息用于其他任何用途。
      3.2 为了促成交易或协助解决争议，某些情况下只有共享您的用户信息，才能促成交易或处理您与他人的纠纷或争议，例如，在三叠云轻商城上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，三叠云有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成。
      3.3 为保护三叠云平台及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据适用的法律或三叠云平台相关协议、规则披露关于您的用户信息。例如，若您作为三叠云商城服务商销售虚假服务，我们可能会公开披露您的主体信息与处罚情况。
      3.4 随着我们的发展，三叠云可能与其他法律主体者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到用户信息转让，我们会要求新的持有您用户信息的公司、组织继续受本条款的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
      3.5 根据相关法律等规定，在以下情形中，我们可以不经您同意而共享、转让、公开披露您的信息：
      3.5.1 履行法律法规规定的义务相关的，例如执行有权机关的要求。
      3.5.2 与国家安全、国防安全直接相关的。
      3.5.3 与公共安全、公共卫生、重大公共利益直接相关的。
      3.5.4 与刑事侦查、起诉、审判和判决执行等直接相关的。
      3.5.5 出于维护用户或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的。
      3.5.6 用户自行向社会公众公开的个人信息。
      3.5.7 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      <h3>四、我们如何存储保护和管理您的个人信息</h3>
      4.1 我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。
      4.2 我们会采取合理可行的措施，尽力避免收集无关的用户信息。我们只会在达成本条款所述目的所需的期限内保留您的用户信息，除非受到法律法规、有关规定的允许或者为执行相关服务条款或本条款、处理投诉/纠纷，或者是保护他人的人身和财产安全或合法权益所合理必需的。超出上述用户信息保存期限后，我们会对您的个人信息进行删除或匿名化处理。
      4.3 我们努力保障信息安全，以防信息的丢失、不当使用、未经授权阅览或披露。
      4.3.1 我们使用各种安全技术以保障信息的安全。例如，我们将努力采取加密等安全保护措施，防止用户信息遭到未经授权的访问或修改、泄露、毁损或丢失。
      4.3.2 我们建立严格的管理制度和流程以保障信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务。
      4.3.3 我们非常重视信息安全合规工作，三叠云作为无代码厂商，切实保障用户系统安全和保护用户数据、ISO27001信息安全管理体系认证等，以业界先进的解决方案充分保障您的信息安全。
      4.3.4 在不幸发生用户信息安全事件（泄露、丢失等），我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，并向您给出安全处理建议。难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。
      4.3.5 同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。
      4.4 为更有效的保障您的信息安全，我们也希望您能够加强自我保护意识和措施。我们仅在因三叠云过错直接导致您信息泄露的范围内承担责任，请您妥善保管您的账号及密码信息，避免您的信息泄露，除非您判断认为必要的情形下，不向任何第三人提供您的账号密码等信息。
      4.5 如出现三叠云产品和服务停止运营的情形，我们会采取合理措施保护您用户信息安全，包括及时停止继续收集用户信息的活动；停止运营的通知将以逐一送达或公告的形式通知用户；并对所持有的个人信息进行删除或匿名化处理等。
      4.6 您的权利带格式的:字体:（中文）Arial 按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使 以下权利：
      4.6.1 访问您的个人信息 您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式 自行访问：
      账户信息——为了您的个人信息安全，您的密码会进行脱敏显示。您可以通过【“我的”->“个人设置”】查看 您的用户头像、姓名、邮箱、账号、手机。
      4.6.2 更正您的个人信息 当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过【“我的”->“个人 设置”】中点击相关信息进行修改。
      4.6.3 删除、用户账户注销、撤回已同意的授权等权限会在之后的版本更新。
      <h3>五、数据使用过程中涉及的合作方</h3>
      5.1 三叠云会以下面所列出的目的，来收集和使用您的信息：
      允许应用修改或删除存储卡上的照片、媒体内容和文件，管理本地文件；允许应用使用相机拍摄照片和视频，方便用户拍摄头像；允许应用读取存储卡上的相册、照片、媒体内容和文件方便用户上传头像；允许应用获取IP地址、mac地址、IMEI\IMSI、SIM卡序列号(ICCID/硬件序列号)、Android ID (限Android系统)等设备信息，作为识别用户；允许打开文件读取流，用来内容分享；允许收集GPS位置信息或WiFi接入点进行记录，我们会获取您的加速度传感器、陀螺仪传感器、旋转矢量传感器以便辅助判断您的位置。
      5.2 消息通知
      在您使用三叠云服务过程中，我们可能会通过短信，push消息、工作通知、弹窗等方式向您发送一种或多种类型的消息，如服务通知、验证码等。为了保障您可以正常接收推送内容，避免消息遗漏，我们使用了自启动和关联启动其他App功能。
      5.3 三叠云可能包含或链接至第三方提供的服务。该服务由第三方提供或运营，您使用第三方的服务以及向第三方提供的信息，将受该等第三方的用户协议和隐私条款约束，而不受本隐私政策的约束。我们对于我们链接到的其他网站或应用的内容或安全性不承担法律责任。
      5.4 其他合作场景
      5.4.1 为保障三叠云App的稳定运行或实现相关功能，我们可能会接入由第三方提供的软件开发包（SDK）实现前述目的。我们会尽到审慎义务，对合作方获取信息的软件工具开发包（SDK）进行严格的安全监测，以保护数据安全。
      5.4.2 我们接入的第三方SDK主要服务于您以及您所代表的企业的需求，因此在满足新的服务需求及业务功能变更时，我们可能会调整我们接入的第三方SDK。我们会及时在本说明中向您公开说明接入第三方SDK的最新情况。目前三叠云App接入的第三方SDK列明如下：
      支付宝
      运营方：支付宝（中国）网络技术有限公司
      使用场景：可帮助用户完成收付款及交易风控、提供人脸识别安全认证服务、在三叠云内打开支付宝小程序框架服务
      使用目的：满足用户使用支付宝快速登录、人脸识别、收付款及交易风控
      收集个人信息类型：设备标识符（imsi、imei、MAC地址、AndroidID、SSID、BSSID、OAID、UDID）、通讯录权限、剪切板信息、应用安装列表、日历信息、位置信息、IP或网关地址、摄像头或麦克风权限、读取外部存储目录、读取通话记录权限、拨号权限、WI-FI信息、设备登录的账号信息、短信权限、运营商名称和代码、网络类型、ICCID
      共享方式：内嵌SDK
      隐私权政策链接：https://render.alipay.com/p/c/k2cx0tg8
      微信
      运营方：深圳市腾讯计算机系统有限公司
      使用场景：支持 微信 授权登录、微信分享和微信支付
      使用目的：满足用户使用 微信 快速登录，分享截图、内容给好友
      收集个人信息类型：设备标识符（IMEI、Android ID、Serial），MAC地址、WLAN接入点 、分享图片或内容、读写外部存储目录
      共享方式：内嵌SDK
      隐私权政策链接：https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8
      QQ
      运营方：深圳市腾讯计算机系统有限公司
      使用场景：支持 QQ 授权登录、QQ分享
      使用目的：满足用户使用 QQ 快速登录，分享截图、内容给好友
      收集个人信息类型：设备标识符（IMEI、Android ID、IMSI）、MAC地址、IP 地址、位置信息（如GPS）、分享图片或内容、读写外部存储目录
      共享方式：内嵌SDK
      隐私权政策链接：https://wiki.connect.qq.com/qq互联sdk隐私保护声明
      钉钉
      运营方：钉钉（中国）信息技术有限公司
      使用场景：支持 钉钉 授权登录、钉钉分享
      使用目的：满足用户使用 钉钉 快速登录，分享截图、内容给好友
      收集个人信息类型：设备标识符（IMEI、Android ID、Serial），MAC地址、WLAN接入点 、分享图片或内容
      共享方式：内嵌SDK
      隐私权政策链接：https://terms.alicdn.com/legal-agreement/terms/suit_bu1_dingtalk/suit_bu1_dingtalk202010070946_49604.html?spm=a213l2.13146415.4929779444.170.7f151d5a7AB5RF
      高德位置服务
      运营方：高德软件有限公司
      使用场景：帮助用户在设置基于位置实现考勤打卡时的位置定位、对话框中发送或共享位置
      使用目的：满足用户使用定位、共享位置
      收集个人信息类型：位置信息（包括GNSS信息、IP地址、WLAN热点、基站信息、蓝牙设备以及其他能够用于定位的传感器信息）、设备信息（包括终端设备唯一标识符（包含IDFV、OAID、IMEI、MEID、IMSI）、mac地址、Andriod ID、操作系统版本、设备品牌及型号、设备配置、运营商类型名称及代码、应用信息（应用名、应用版本号））、WiFi信息、正在运行的应用列表、网络类型、BSSID、MEID、硬件序列号、手机存储权限
      共享方式：内嵌SDK
      隐私权政策链接：https://lbs.amap.com/pages/privacy/
      Bugly 自动收集
      运营方：深圳市腾讯计算机系统有限公司
      使用场景：收集APP崩溃、闪退等异常信息
      使用目的：顺利解决异常问题，提升产品体验
      收集个人信息类型：数据统计及分析 日志信息（包括：第三方开发者自定义日志、Logcat日志以及APP崩溃堆栈信息）、设备 ID（包括：androidid以及idfv）、联网信息、系统名称、系统版本以及国家码、读写外部存储目录、ICCID
      共享方式：内嵌SDK
      隐私权政策链接：https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf
      阿里云OSS存储SDK
      运营方：阿里云计算有限公司
      使用场景：提供文件上传下载服务
      使用目的：满足用户使用上传、下载文件
      收集个人信息类型：外部存储读写，访问网络，查看网络状态，查看修改WIFI状态
      共享方式：内嵌SDK
      隐私权政策链接：http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=5176.10695662.7y9jhqsfz.86.17883475PKzAAQ
      阿里云移动推送
      运营方：阿里云计算有限公司
      使用场景：流程审核待办通知、知识库评论通知、表单评论通知
      使用目的：提供高效、精准、实时的消息推送、离线推送
      收集个人信息类型：设备标识符（IMEI、Android ID、Serial）、MAC地址、查看网络状态、查看修改WIFI状态
      共享方式：内嵌SDK【SDK包含集成了渠道：com.aliyun.ams:alicloud-android-third-push-xiaomi(小米推送)、 alicloud-android-third-push-oppo(OPPO推送)、alicloud-android-third-push-vivo(vivo推送)、alicloud-android-third-push-huawei(华为推送)】
      隐私权政策链接：https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112071754_83380.html?spm=a2c4g.434217.0.0.76b3320ax3pbG8
      <h3>六、我们如何处理未成年人的个人信息</h3>
      一般来说，未经监护人的同意，我们不会主动收集和处理14岁以下儿童的个人信息。但是，由于技术限制，在某些情况下，我们提供在线产品和服务时无法识别用户的年龄。在这些情况下，根据相关法律，我们将用户视为有完全合法地权利向我们提供个人信息。如果我们无意中未经其监护人同意收集了任何未成年人的个人信息，我们将在发现这种情况时或在监护人要求时及时删除。
      <h3>七、隐私政策的修订和通知</h3>
      a.为了给您提供更好的服务，三叠云及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分并具有等同于本隐私政策的效力，未经您明确同意，我们不会削减您依据当前生效的本隐私政策所应享受的权利。
      b.本隐私政策更新后，我们会在三叠云发出更新版本，并在更新后的条款生效前通过官方网站https://www.triascloud.com公告或其他适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。
      c.如果本隐私政策有较大变更，我们也将提供更明显的通知，如当您登录我们的应用时使用弹出窗口，或直接向您发送电子邮件等。本隐私政策的较大变更可能包括对处理个人信息的目的、处理的个人信息的类型以及使用个人信息的方式的重大更改，以及您对个人信息的权利或行使权利的方式的重大更改。
      <h3>八、更正和删除信息</h3>
      8.1 查询、更正和补充您的信息
      您有权查询、更正或补充您的信息。您可以通过以下方式自行完成：
      如需删除账号，请联系三叠云客服或发送邮件至tousu@sandieyun.com或您可以通过APP-【我的】-【安全中心】-【注销账户】申请注销您的账户，账号刪除后您将无法再访问任何三叠元平台。您的登录信息将被删除，您将无法再收到任何电子邮件通知。
      8.2 删除您的信息
      您可以通过“8.1 查询、更正和补充您的信息”中规定的方式删除您的部分信息。
      在以下情况下，您可以向我们提交删除您的信息的请求：
      如果我们对信息的处理违反法律或法规；
      如果我们未经您的同意收集和使用您的信息；
      如果我们对信息的处理违反了我们与您的协议；
      如果您不再使用我们的产品或服务或您已自愿注销您的帐户；
      如果我们不再永久为您提供产品或服务。
      当我们决定回应您的删除请求时，我们还将通知从我们这里获取您的信息的实体，并要求其立即删除，除非法律法规另有规定，或该实体已获得您的独立授权。
      在您删除相关信息后，无论是否有我们的帮助，由于适用法律或安全技术的限制，我们可能无法立即从备份系统中删除相应信息。我们将以安全的方式保留您的信息，并进一步将其与其他信息隔离，直到可以删除或匿名备份。
      <h3>九、联系我们</h3>
      三叠云服务的提供者为：广州三叠纪元智能科技有限公司，其注册地址为：广州市黄埔区科学大道182号C1区601。 如果您对个人信息保护问题有投诉、建议、疑问，您可以将问题发至：tousu@sandieyun.com将尽快审核所涉问题，并在验证您的用户身份后的十五个工作日内回复。
    </pre>
  </contract-page>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import ContractPage from './contract.vue';
@Component({
  components: { ContractPage },
})
export default class PrivacyPage extends Vue {}
</script>
<style lang="less" module>
.privacy {
  pre {
    line-height: 40px;
    white-space: pre-line;
  }
}
</style>
