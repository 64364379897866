<template>
  <a-form-model
    :class="[pageStyle.pageBody, authBindStyle.form]"
    ref="form"
    :model="model"
    :rules="rules"
    hide-required-mark
    @submit="handleLoginBind"
  >
    <a-form-model-item prop="phone">
      <x-input
        v-model="model.phone"
        name="phone"
        :placeholder="$t('common.username') + '/' + $t('common.phone')"
        autocomplete="username"
        :maxLength="20"
      >
        <x-icon slot="prefix" type="tc-icon-user" />
      </x-input>
    </a-form-model-item>
    <a-form-model-item prop="verifyCode">
      <x-input
        v-model="model.verifyCode"
        name="verifyCode"
        :maxLength="6"
        :placeholder="$t('valid.input', { value: $t('common.code') })"
        autocomplete="one-time-code"
      >
        <x-icon slot="prefix" type="tc-icon-mail" />
        <async-button
          type="link"
          slot="suffix"
          :disabled="!!countDownSecond"
          :click="sendCode"
        >
          {{
            countDownSecond
              ? `${countDownSecond}S`
              : firstGetCode
              ? $t('login.getCode')
              : $t('login.resend')
          }}
        </async-button>
      </x-input>
    </a-form-model-item>
    <a-form-model-item prop="inviteCode">
      <x-input
        autocomplete="one-time-code"
        v-model="model.inviteCode"
        name="inviteCode"
        :maxLength="7"
        :placeholder="$t('valid.input', { value: $t('common.inviteCode') })"
      >
        <x-icon slot="prefix" type="tc-icon-push" />
      </x-input>
    </a-form-model-item>
    <a-form-model-item
      prop="agree"
      :class="[authBindStyle.toChange, $style.agree]"
    >
      <a-checkbox v-model="model.agree" name="agree">
        <span>{{ $t('register.agree') }}</span>
        <a target="_blank" href="/contract">{{ $t('register.terms') }}</a>
      </a-checkbox>
    </a-form-model-item>
    <async-button block type="primary" html-type="submit">
      {{ $t('register.bind') }}
    </async-button>
    <div :class="[pageStyle.pageFooter, $style.footer]">
      {{ $t('register.hasAccount') }}？
      <router-link to="/auth-bind/account">
        {{ $t('oauth.bind') }}
      </router-link>
    </div>
  </a-form-model>
</template>
<script>
import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import { match, delay } from '@triascloud/utils';
import { validateFieldAsync } from '@/utils';
import { getCaptcha, sendVerifyCode } from '@/services/account';
import { bindRegister } from '@/services/oauth';
import AsyncButton from '@/components/async-button';
import { SSOContext } from '@/views/context';

@Component({
  components: { AsyncButton },
})
export default class AuthBindRegisterPage extends Vue {
  @Inject(SSOContext.Root) root;
  @Inject(SSOContext.PageStyle) pageStyle;
  @Inject(SSOContext.AuthBindStyle) authBindStyle;
  @Prop({ type: String, default: '' }) code;
  @Prop({ type: String, default: '' }) state;
  countDownSecond = 0;
  firstGetCode = true;
  model = {
    phone: '',
    inviteCode: '',
    verifyCode: '',
    inviteKey: '',
    agree: false,
  };

  get rules() {
    return {
      phone: [
        {
          required: true,
          message: this.$t('valid.input', { value: this.$t('common.phone') }),
        },
        {
          pattern: match.REG_PHONE,
          message: this.$t('valid.phone'),
          trigger: 'blur',
        },
      ],
      verifyCode: [
        {
          required: true,
          message: this.$t('valid.required', {
            value: this.$t('common.code'),
          }),
        },
        {
          pattern: match.REG_PURE_NUMBER,
          message: this.$t('valid.code'),
        },
      ],
      inviteCode: [
        // {
        //   required: true,
        //   message: this.$t('valid.input', {
        //     value: this.$t('common.inviteCode'),
        //   }),
        // },
        // {
        //   pattern: /^[A-Za-z0-9]{6}/,
        //   message: this.$t('valid.inviteCode'),
        //   trigger: 'blur',
        // },
      ],
    };
  }

  async reloadCaptcha() {
    if (!this.needCaptcha) return;
    this.captcha = await getCaptcha(this.model.phone);
  }

  async sendCode() {
    await validateFieldAsync(this.$refs.form, 'phone');
    const result = await sendVerifyCode(this.model.phone, 'registerCodeSms');
    if (typeof result === 'string' && /^\d{6}$/.test(result)) {
      this.model.verifyCode = result;
      validateFieldAsync(this.$refs.form, 'verifyCode');
    }
    this.countDown(60);
    if (this.firstGetCode) this.firstGetCode = false;
  }

  async countDown(second) {
    this.countDownSecond = second;
    if (second <= 0) return;
    await delay(1000);
    this.countDown(second - 1);
  }

  async handleLoginBind(ev) {
    ev && ev.preventDefault();
    await this.$refs.form.validate();
    const payload = this.root.authBindPayload;
    if (!this.model.agree) {
      this.$message.error(this.$t('valid.agree'));
      return;
    }
    const token = await bindRegister(payload.type, payload.uuid, this.model);
    await this.root.setStorage(token, true);
    this.root.to = this.root.home;
    this.$router.push('/success');
  }
}
</script>
<style lang="less" module>
.agree :global(.ant-form-item-control) {
  text-align: right;
  :global(.ant-checkbox-wrapper) {
    color: var(--font-info);
  }
  :global(.ant-form-explain) {
    bottom: -12px;
  }
}
.footer {
  justify-content: center;
  border-top: 1px solid var(--border);
}
</style>
