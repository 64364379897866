<template>
  <div :class="$style.dingtalk">
    <qr-connect-dingtalk @connect="handleLogin" ref="iframe" />
    <a-checkbox v-model="rememberMe">{{ $t('login.rememberMe') }}</a-checkbox>
  </div>
</template>
<script>
import { Component, Vue, Inject } from 'vue-property-decorator';
import { QrConnectDingtalk } from '@/components/qr-connect';
import { SSOContext } from '../context';
import { loginWithDingTalkCode } from '@/services/account';

@Component({
  components: { QrConnectDingtalk },
})
export default class LoginDingTalkPage extends Vue {
  @Inject(SSOContext.FormStyle) formStyle;
  @Inject(SSOContext.Root) root;
  rememberMe = false;
  async handleLogin({ code }) {
    try {
      const token = await loginWithDingTalkCode(
        code,
        this.root.currentTenantId,
        this.root.inviteKey,
      );
      this.$message.success(this.$t('login.loginSuccess'));
      await this.root.setStorage(token, this.rememberMe);
      this.root.redirect();
    } catch {
      this.$refs.iframe.refresh();
    }
  }
}
</script>
<style module type="less">
.dingtalk {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
