<template>
  <div></div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { getOauth } from '@/services/custom-oauth';

@Component
export default class SsoOauthPage extends Vue {
  async created() {
    const data = await getOauth(this.$route.params.type);
    if (!data || !data.user_authorization_body.uri)
      return this.$message.error('登录链接失效');
    const url = `${data.user_authorization_body.uri}?${this.generateQuery(
      data.user_authorization_body.params,
      data,
    )}`;
    location.replace(url);
  }
  generateQuery(params, data) {
    return params
      .map(
        item =>
          `${item.key}=${
            item.option === 'CUSTOM'
              ? encodeURIComponent(item.value || '')
              : this.getDynamic(item.option, data)
          }`,
      )
      .concat(`state=${data.state}`)
      .join('&');
  }
  getDynamic(option, data) {
    switch (option) {
      case 'CLIENT_ID':
        return data.client_id || '';
      case 'REDIRECT_URI':
        return encodeURIComponent(
          `${location.origin}/sso/cb?${
            this.$route.query.to
              ? `to=${encodeURIComponent(this.$route.query.to)}`
              : ''
          }response_type=${
            data.user_authorization_body.params.find(
              item => item.key === 'response_type',
            )?.value || 'code'
          }`,
        );
      default:
        return '';
    }
  }
}
</script>
