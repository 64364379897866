import Vue from 'vue';
import Vuex from 'vuex';
import { createCrossStorageVuexPlugin } from '@triascloud/cross-storage';
import { ensureLocale } from '@triascloud/i18n';
import { ThemeSelect } from '@triascloud/x-blocks';
import { safeParseJSON } from '@triascloud/utils';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    changed: {
      skin: false,
      locale: false,
    },
  },
  mutations: {
    habitChange(state, type) {
      Vue.set(state.changed, type, true);
    },
  },
  plugins: [
    createCrossStorageVuexPlugin({
      keys: {
        skin: json => ThemeSelect.ensureTheme(json),
        locale: locale => ensureLocale(locale),
        tenant: json => safeParseJSON(json, () => null),
      },
    }),
  ],
});
