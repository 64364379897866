<template>
  <a-form-model
    :class="formStyle.form"
    ref="form"
    :model="model"
    :rules="rules"
    hide-required-mark
    @submit="handleLogin"
  >
    <a-form-model-item prop="phone">
      <x-input
        v-model="model.phone"
        name="phone"
        :maxLength="11"
        :placeholder="$t('valid.input', { value: $t('common.phone') })"
        autocomplete="tel"
      >
        <x-icon slot="prefix" type="tc-icon-mobile" />
      </x-input>
    </a-form-model-item>
    <a-form-model-item prop="verifyCode">
      <x-input
        v-model="model.verifyCode"
        name="verifyCode"
        :maxLength="6"
        :placeholder="$t('valid.input', { value: $t('common.code') })"
        autocomplete="off"
      >
        <x-icon slot="prefix" type="tc-icon-mail" />
        <async-button
          type="link"
          slot="suffix"
          :disabled="!!countDownSecond"
          :click="sendCode"
        >
          {{
            countDownSecond
              ? `${countDownSecond}S`
              : firstGetCode
              ? $t('login.getCode')
              : $t('login.resend')
          }}
        </async-button>
      </x-input>
    </a-form-model-item>
    <a-form-model-item prop="captcha" v-if="needCaptcha">
      <x-input
        :class="formStyle.captcha"
        v-model="model.captcha"
        name="captcha"
        :placeholder="$t('valid.input', { value: $t('common.captcha') })"
        autocomplete="one-time-code"
      >
        <x-icon slot="prefix" type="tc-icon-mail" />
        <img slot="suffix" :src="captcha" alt="" @click="reloadCaptcha" />
      </x-input>
    </a-form-model-item>
    <a-form-model-item prop="rememberMe" :class="$style.rememberMe">
      <a-checkbox v-model="model.rememberMe">{{
        $t('login.rememberMe')
      }}</a-checkbox>
      <router-link to="/email"
        >{{ $t('common.email') }}{{ $t('common.code') }}</router-link
      >
    </a-form-model-item>
    <a-button type="primary" block html-type="submit" :loading="submitting">
      {{ $t('login.login') }}
    </a-button>
  </a-form-model>
</template>
<script>
import { Component, Vue, Inject } from 'vue-property-decorator';
import AsyncButton from '@/components/async-button';
import { delay, match } from '@triascloud/utils';
import { validateFieldAsync } from '@/utils';
import {
  sendVerifyCode,
  loginWithVerifyCode,
  getCaptcha,
} from '@/services/account';
import { ResponseError } from '@/enum';
import { SSOContext } from '../context';
// import InviteCodeModal from './components/invite-code-modal.vue';

@Component({
  components: { AsyncButton },
})
export default class LoginDefaultPhone extends Vue {
  @Inject(SSOContext.Root) root;
  @Inject(SSOContext.FormStyle) formStyle;
  countDownSecond = 0;
  needCaptcha = false;
  captcha = '';
  model = {
    phone: '',
    verifyCode: '',
    captcha: '',
    inviteCode: '', // 注册邀请码
    rememberMe: true,
  };
  submitting = false;
  firstGetCode = true;
  get rules() {
    return {
      phone: [
        {
          required: true,
          message: this.$t('valid.input', { value: this.$t('common.phone') }),
        },
        {
          pattern: match.REG_PHONE,
          message: this.$t('valid.phone'),
          trigger: 'blur',
        },
      ],
      captcha: {
        required: true,
        message: this.$t('valid.input', { value: this.$t('common.code') }),
      },
      verifyCode: [
        {
          required: true,
          message: this.$t('valid.required', {
            value: this.$t('common.code'),
          }),
        },
        {
          len: 6,
          message: this.$t('valid.code'),
          trigger: 'blur',
        },
      ],
    };
  }

  async reloadCaptcha() {
    if (!this.needCaptcha) return;
    this.captcha = await getCaptcha(this.model.phone);
  }

  async handleLogin(ev) {
    ev && ev.preventDefault();
    await this.$refs.form.validate();
    try {
      this.submitting = true;
      const token = await loginWithVerifyCode(
        { ...this.model, inviteKey: this.root.inviteKey },
        this.root.currentTenantId,
      );
      this.$message.success(this.$t('login.loginSuccess'));
      await this.root.setStorage(token, this.model.rememberMe);
      this.root.redirect();
    } catch (error) {
      if (!error) return;
      switch (error.code) {
        // case ResponseError.LOGIN_USER_NOT_FOUND: {
        //   const { inviteCode } = await InviteCodeModal.create();
        //   this.model.inviteCode = inviteCode;
        //   await this.handleLogin(ev);
        //   return;
        // }
        case ResponseError.LOGIN_NEED_CAPTCHA:
        case ResponseError.LOGIN_CAPTCHA_ERROR:
          this.needCaptcha = true;
          this.reloadCaptcha();
          break;
        case ResponseError.LOGIN_PASSWORD_ERROR:
          this.reloadCaptcha();
          break;
      }
      this.$message.error(error.message);
    } finally {
      this.model.inviteCode = '';
      this.submitting = false;
    }
  }

  async sendCode() {
    await validateFieldAsync(this.$refs.form, 'phone');
    const result = await sendVerifyCode(this.model.phone, 'loginSms');
    if (typeof result === 'string' && /^\d{6}$/.test(result)) {
      this.model.verifyCode = result;
      validateFieldAsync(this.$refs.form, 'verifyCode');
    }
    this.countDown(60);
    if (this.firstGetCode) this.firstGetCode = false;
  }

  async countDown(second) {
    this.countDownSecond = second;
    if (second <= 0) return;
    await delay(1000);
    this.countDown(second - 1);
  }
}
</script>
<style lang="less" module>
.rememberMe :global(.ant-form-item-children) {
  display: flex;
  justify-content: space-between;
}
</style>
