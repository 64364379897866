<template>
  <a-form-model
    :class="formStyle.form"
    ref="form"
    :model="model"
    :rules="rules"
    hide-required-mark
    @submit="handleLogin"
  >
    <a-form-model-item prop="account">
      <x-input
        v-model="model.account"
        name="account"
        :placeholder="`${$t('common.username')}/${$t('common.phone')}/${$t(
          'common.email',
        )}`"
        autocomplete="username"
        :maxLength="50"
      >
        <x-icon slot="prefix" type="tc-icon-user" />
      </x-input>
    </a-form-model-item>
    <a-form-model-item prop="password">
      <x-input
        v-model="model.password"
        name="password"
        :placeholder="$t('common.password')"
        autocomplete="current-password"
        :maxLength="20"
        type="password"
      >
        <x-icon slot="prefix" type="tc-icon-lock" />
      </x-input>
    </a-form-model-item>
    <a-form-model-item prop="captcha" v-if="needCaptcha">
      <x-input
        :class="formStyle.captcha"
        v-model="model.captcha"
        name="captcha"
        :placeholder="$t('valid.input', { value: $t('common.captcha') })"
        autocomplete="off"
      >
        <x-icon slot="prefix" type="tc-icon-mail" />
        <img slot="suffix" :src="captcha" alt="" @click="reloadCaptcha" />
      </x-input>
    </a-form-model-item>
    <template v-if="needVerifyCode">
      <a-form-model-item prop="phone">
        <x-input
          :value="user.mobilePhone"
          disabled
          name="phone"
          :placeholder="$t('valid.input', { value: $t('common.phone') })"
          autocomplete="tel"
        >
          <x-icon slot="prefix" type="tc-icon-mobile" />
        </x-input>
      </a-form-model-item>
      <a-form-model-item prop="verifyCode">
        <x-input
          v-model="model.verifyCode"
          name="captcha"
          :placeholder="$t('valid.input', { value: $t('common.code') })"
          autocomplete="off"
        >
          <x-icon slot="prefix" type="tc-icon-mail" />
          <async-button
            type="link"
            slot="suffix"
            :disabled="!!countDownSecond"
            :click="sendCode"
          >
            {{
              countDownSecond
                ? `${countDownSecond}S`
                : firstGetCode
                ? $t('login.getCode')
                : $t('login.resend')
            }}
          </async-button>
        </x-input>
      </a-form-model-item>
    </template>
    <a-form-model-item prop="rememberMe" :class="$style.rememberMe">
      <a-checkbox v-model="model.rememberMe">{{
        $t('login.rememberMe')
      }}</a-checkbox>
      <router-link to="/reset">{{ $t('login.forgotPassword') }}?</router-link>
    </a-form-model-item>
    <a-button html-type="submit" type="primary" block :loading="submitting">
      {{ $t('login.login') }}
    </a-button>
  </a-form-model>
</template>
<script>
import { Component, Vue, Inject } from 'vue-property-decorator';
import { delay, match } from '@triascloud/utils';
import { ResponseError } from '@/enum';
import AsyncButton from '@/components/async-button/index.vue';
import {
  getCaptcha,
  loginWithPassword,
  sendVerifyCodeByAccount,
  getDesensitizedPhone,
} from '@/services/account';
import { SSOContext } from '../context';
import { validateFieldAsync } from '@/utils';

@Component({
  components: { AsyncButton },
})
export default class LoginDefaultPage extends Vue {
  /** @type { import('../index.vue').default } */
  @Inject(SSOContext.Root) root;
  @Inject(SSOContext.FormStyle) formStyle;
  needCaptcha = false;
  captcha = '';
  needVerifyCode = false;
  countDownSecond = 0;
  firstGetCode = true;
  model = {
    account: '',
    password: '',
    captcha: '',
    rememberMe: true,
    // phone: '',
    verifyCode: null,
  };
  submitting = false;
  get rules() {
    return {
      account: {
        required: true,
        message: this.$t('valid.required', {
          value: this.$t('common.account'),
        }),
      },
      // phone: [
      //   {
      //     required: true,
      //     message: this.$t('valid.input', { value: this.$t('common.phone') }),
      //   },
      //   {
      //     pattern: match.REG_PHONE,
      //     message: this.$t('valid.phone'),
      //     trigger: 'blur',
      //   },
      // ],
      verifyCode: [
        {
          required: true,
          message: this.$t('valid.required', {
            value: this.$t('common.code'),
          }),
        },
        {
          len: 6,
          message: this.$t('valid.code'),
          trigger: 'blur',
        },
      ],
      captcha: {
        required: true,
        message: this.$t('valid.input', { value: this.$t('common.captcha') }),
      },
      password: [
        {
          required: true,
          message: this.$t('valid.required', {
            value: this.$t('common.password'),
          }),
        },
        {
          pattern: match.REG_PASSWORD,
          message: this.$t('valid.password'),
          trigger: 'blur',
        },
      ],
    };
  }

  async reloadCaptcha() {
    if (!this.needCaptcha) return;
    this.captcha = await getCaptcha(this.model.account);
    this.model.captcha = '';
  }

  async handleLogin(ev) {
    ev && ev.preventDefault();
    await this.$refs.form.validate();
    try {
      this.submitting = true;
      const token = await loginWithPassword(
        { ...this.model, inviteKey: this.root.inviteKey },
        this.root.currentTenantId,
      );
      this.$message.success(this.$t('login.loginSuccess'));
      await this.root.setStorage(token, this.model.rememberMe);
      this.root.redirect();
    } catch (error) {
      if (!error) return;
      switch (error.code) {
        case ResponseError.LOGIN_NEED_CAPTCHA:
        case ResponseError.LOGIN_CAPTCHA_ERROR:
          this.needCaptcha = true;
          this.reloadCaptcha();
          break;
        case ResponseError.LOGIN_PASSWORD_ERROR:
          this.reloadCaptcha();
          this.model.verifyCode = '';
          break;
        case ResponseError.LOGIN_NEED_VERIFY_CODE:
        case ResponseError.LOGIN_VERIFY_CODE_ERROR:
          this.getDesensitizedPhone();
          this.needVerifyCode = true;
          break;
        default:
          throw error;
      }
    } finally {
      this.submitting = false;
    }
  }

  async sendCode() {
    // await validateFieldAsync(this.$refs.form, 'phone');
    // const result = await sendVerifyCode(this.model.phone, 'loginSms');
    const result = await sendVerifyCodeByAccount(this.user.pkId);
    if (typeof result === 'string' && /^\d{6}$/.test(result)) {
      this.model.verifyCode = result;
      validateFieldAsync(this.$refs.form, 'verifyCode');
    }
    this.countDown(60);
    if (this.firstGetCode) this.firstGetCode = false;
  }
  async countDown(second) {
    this.countDownSecond = second;
    if (second <= 0) return;
    await delay(1000);
    this.countDown(second - 1);
  }

  user = {
    mobilePhone: '',
    pkId: '',
  };
  async getDesensitizedPhone() {
    this.user = await getDesensitizedPhone(this.model.account);
  }
}
</script>
<style lang="less" module>
.rememberMe :global(.ant-form-item-children) {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
</style>
